var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_fun_template_resource_page_type bbox_wrapper" },
    [
      _c(
        "div",
        { staticClass: "page_type_wrapper d-flex align-center" },
        [
          _vm._l(_vm.typeList, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "page_type",
                  class: {
                    on: item.value === _vm.resourcePageStatus.pageType,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.changePageType(item.value)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
              ),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }