class PChat{
  constructor(params){
    const {
      chatId,
      chatName,
      createTime,
      createUserId,
      jurisdiction,
      photo,
      readflag,
      secondleveContent,
      sidebarflag,
      users,
    } = params || {};

    this.chatId            = chatId;
    this.chatName          = chatName;
    this.createTime        = createTime;
    this.createUserId      = createUserId;
    this.jurisdiction      = jurisdiction;
    this.photo             = photo;
    this.readflag          = readflag;
    this.secondleveContent = secondleveContent;
    this.sidebarflag       = sidebarflag;
    this.users             = users;
  }


}

export default PChat;
