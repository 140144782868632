<!--
 * @Author       : Hugo
 * @Date         : 2020-05-25 11:33:25
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-12-01 11:43:36
 * @Description  :
 * @FilePath     : /miaohang/src/components/Group/Aside/Task.vue
-->



<template>
  <div class="group_aside_task">
    <div class="wrapper">
      <SidebarSearchGroup class="sidebar_search_group" ref="sidebar_search_group"></SidebarSearchGroup>
      <group-aside-search v-if="search_switch"></group-aside-search>
      <!-- 默认状态 -->
      <div class="default_status" id="default_status" v-show="!search_switch">
        <template v-for="(item, index) in message_box_list">
          <ContactUnit
            class="cp un_sel"
            stick="0"
            v-if="item.chatType == 0"
            :key="`message_box_list${item.chatId}_${index}`"
            :messageData="PMessageBox(item)"
            @click.stop.native="goSingleDialog(item)"
            :ref="`chatId${item.chatId}`"
          >
            <template v-slot:name>
              <span v-html="highlightKeywords(item.name || item.updateusername)">name1</span>
            </template>
            <template v-slot:desc>
              <span v-html="PMessageBox(item).viewMessageContent()"></span>
            </template>
            <template v-slot:time>
              <span>
                {{ item.lastSendTime | universalDate({ formatStr:'MM月DD日', noTime: true }) }}
              </span>
            </template>

          </ContactUnit>
          <WorkTeamUnit
            class="cp un_sel"
            :userlist="item.pictures"
            stick="0"
            :item="item"
            v-if="item.chatType == 1"
            :key="`message_box_list${item.chatId}_${index}`"
            :messageData="PMessageBox(item)"
            @click.stop.native="goGroupDialog(item)"
            :ref="`chatId${item.chatId}`"
          >
            <template v-slot:name>
              <span v-html="highlightKeywords(item.name)">name1</span>
            </template>
            <template v-slot:desc>
              <!-- <span v-html="(item.isdisturb && item.unreadMessageNum ? `[${unread(item.unreadMessageNum)}条] ` : '') + highlightKeywords((item.updateusername? item.updateusername + ' ' : '')+item.messageContent)"></span> -->
              <span
                v-html="PMessageBox(item).viewMessageContent()"
              ></span>
            </template>
            <template v-slot:time>
              <span>{{item.lastSendTime | universalDate({formatStr:'MM月DD日', noTime: true})}}</span>
              <!-- <span>{{item.lastSendTime | formatDate('HH:mm')}}</span> -->
            </template>
          </WorkTeamUnit>
        </template>
      </div>

    </div>
  </div>
</template>
<script>
import {PMessageBox} from '@/types';
import SidebarSearchGroup from "@/components/sidebar/searchGroup.vue";
import ContactUnit from "@/components/sidebar/contactUnit.vue";
import WorkTeamUnit from "@/components/sidebar/workTeamUnit.vue";
import GroupAsideSearch from "@/components/Group/Aside/Search.vue";

export default {
  components: {
    SidebarSearchGroup,
    ContactUnit,
    WorkTeamUnit,
    GroupAsideSearch,
  },
  data() {
    return {
      // message_box_list: null,
       // scrollto 配置
      options: {
        container: '#default_status', //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      },

    };
  },
  computed: {
    // 消息盒子
    message_box_list() {
      return this.$store.state.message_box_list;
    },
    // 搜索
    search_switch(){
      return this.$store.state.common.search_switch;
    },
  },
  watch: {
    /**
     * 监听消息盒子,如果为null则获取
     */
    message_box_list: {
      handler: function (newval) {
        if (newval == null) {
          // console.log(newval);
        }
      },
      deep: true,
    },
    search_switch: function(){},
    $route: {
      handler: function(val){
        // this.scrollToCurrentTask(val);
      },
      deep: true,
    }
  },
  methods: {
    // 搜索高亮
    highlightKeywords(str, keywords) {
      return this.$tools.highlightKeywords(str, keywords);
    },
    /**
     * @description: 设置消息盒子(store)
     * @param {type}
     * @return:
     */
    async set_message_box_list() {
      if (this.message_box_list == null) {
        await this.$store.dispatch("get_message_box_list");
      }
    },
    async getFriendList(){
      let res = await this.$http.request("/projectFriends/friendsList", {})
      if (res.contents.list.length !== 0) {
        this.$store.commit('set_friends_list', res.contents.list)
      }
    },
    /**
     * 初始化
     */
    async init() {
      await this.getFriendList();
      await this.set_message_box_list();
      // 滚动到相应位置
      // this.scrollToCurrentTask(this.$route);
      let message_box_unit = this.$store.state.message_box_unit;
      if(!message_box_unit){
        let route = this.$route;
        if(route.params.id){
          let chatType = '';
          if(route.name == 'fun_task_dialog_single'){
            chatType = '0';
          }else if(route.name == 'fun_task_dialog_group'){
            chatType = '1';
          }

          let unit = this.message_box_list.find( item => {
            // return item.chatId == route.params.id && item.chatType  == chatType;
            return item.chatId == this.$api.moduleTask.getRouteParamsId() && item.chatType  == chatType;
          })
          if(unit){
            this.$store.commit('set_message_box_unit', unit);
          }
        }
      }
    },
    /**
     * 单聊
     */
    goSingleDialog (item) {
      console.log(item)
      this.$router.push({
        name: "fun_task_dialog_single",
        params: { id: this.$tools.btoa(item.chatId) },
      });
      this.$store.commit("set_single_dialog_data", item);
      this.$store.commit("set_contact_data", item); // 设置联系人信息
      this.$store.commit("set_message_box_unit", item); // 替代上两项
      this.$store.dispatch("close_all_popover"); // 关闭所有弹出框
      this.$store.commit("set_chat_id", item.chatId); // 设置会话id
      if(item.unreadMessageNum && item.unreadMessageNum != 0){
        // console.log('置空')
        // 将当前chatid未读消息置空
        this.$http.request('/projectTask/unreadTozero', {chat_id:item.chatId, chat_type:item.chatType});
        item.unreadMessageNum = 0;
      }
    },
    /**
     * 群聊
     */
    goGroupDialog(item) {
      // console.log(item, this.$tools.btoa(item.chatId), item.chatId)
      this.$router.push({
        name: "fun_task_dialog_group",
        params: { id: this.$tools.btoa(item.chatId) },
      })
      this.$store.commit("set_task_group_dialog_title_data", item)
      this.$store.commit("set_workteam_data", item)
      this.$store.commit("set_message_box_unit", item) // 替代上两项
      this.$store.dispatch("close_all_popover") // 关闭所有弹出框
      this.$store.commit("set_chat_id", item.chatId) // 设置会话id
      if (item.unreadMessageNum && item.unreadMessageNum != 0) {
        // console.log('置空')
        // 将当前chatid未读消息置空
        this.$http.request('/projectTask/unreadTozero', {
          chat_id:item.chatId,
          chat_type:item.chatType
        })
        item.unreadMessageNum = 0
      }
      // 关闭 有人@wo
      this.$set(item, 'islinkme', 0)
    },
    /**
     * @description: 滚动到当前聊天位置
     * @param {Object} route 当前路由信息
     * @return {type}
     */
    scrollToCurrentTask(route){
      let routename = route.name;
      // let id = route.params.id;
      let id = this.$api.moduleTask.getRouteParamsId();
      let {message_box_list} = this;
      let has_dialog = message_box_list.find(item => {
        return item.chatId == id;
      })
      // 如果当前会话还不存在 刷新时跳转到index页
      if(!has_dialog){
        this.$router.push({name:'fun_task_index'});
        return;
      }
      if(routename.indexOf('fun_task_dialog') != -1){
        if(id || id == 0){
          // 获取目标dom
          let aim = this.$refs[`chatId${id}`][0].$el;
          setTimeout(() => {
            this.$scrollTo(aim, 50, this.options);
          }, 50);
        }
      }
    },
    PMessageBox(item){
      return new PMessageBox(item);
    },
    unread(num){
      if(num>99){
        return '99+';
      }
      return num;
    }
  },
  async mounted() {
    await this.init();
  },
};
</script>
<style lang="scss" scoped>
.group_aside_task {
  position: relative;
  z-index: 3;
  // width: 250px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @include hidescrollbar;
  position: relative;
  .wrapper {
    @include flex;
    @include transition;
    flex-direction: column;
    overflow-x: visible;
    // width: 250px;
    width: 100%;
    height: 100%;
    .default_status {
      @include hidescrollbar;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      flex: 1;
    }
    .sidebar_search_group {
      // position: sticky;
      // top: 0;
      z-index: 3;
      flex-shrink: 0;
      width: 100%;
    }
    .search_status {
      width: 100%;
      .group {
        width: 100%;
        .title {
          @include bbox;
          padding-left: 17px;
          padding-top: 18px;
          font-size: 14px;

          font-weight: 400;
          color: rgba(153, 153, 153, 1);
        }
        &:last-child {
          .fun_group {
            .box {
              border-bottom: none;
            }
          }
        }
        .fun_group {
          @include bbox;
          padding-left: 17px;
          padding-top: 14px;
          .box {
            @include flex;
            width: 100%;
            border-bottom: 2px solid $border_color;
            padding-bottom: 19px;
            .icon {
              flex-shrink: 0;
              width: 10px;
              height: 8px;
              background: {
                size: 100%;
                image: url(~@/assets/images/common/search_extend.png);
              }
            }
            .text {
              font-size: 12px;

              font-weight: 400;
              color: rgba(0, 84, 166, 1);
              padding-left: 6px;
            }
          }
        }
      }
      .template_group {
        .template_unit {
          @include bbox;
          @include single_line_intercept;
          width: 100%;
          font-size: 14px;

          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          padding: 14px 17px;
        }
      }
      .other_group {
        .other_content {
          @include bbox;
          @include flex;
          width: 100%;
          padding: 15px 17px;
          padding-right: 13px;
          align-items: stretch;
          overflow: hidden;
          .img {
            width: 44px;
            height: 44px;
            display: block;
            flex-shrink: 0;
          }
          .info_group {
            @include flexsb;
            flex-direction: column;
            width: 100%;
            flex: 1;
            align-items: flex-start;
            padding-left: 15px;
            .info_title {
              font-size: 14px;

              font-weight: 400;
              color: rgba(51, 51, 51, 1);
            }
            .info_subtitle {
              @include single_line_intercept;
              font-size: 12px;

              font-weight: 400;
              color: rgba(153, 153, 153, 1);
            }
          }
        }
      }
    }
  }
}
</style>

