<template>
  <div class="default_fun_aside">
    <div class="top_group">
      <div class="logo_group">
        <a href="https://pmcshome.yourutec.com/" target="_blank" class="logo_a">
          <img src="@/assets/logo.png" alt="" class="logo">
        </a>
      </div>
      <div class="fun_group">
        <el-tooltip
          content="沟通频道"
          placement="right"
          effect="light"
          popper-class="fun_aside_tooltip"
        >
          <div
            :to="{name:'fun_task_index'}"
            class="fun fun_task"
            :class="{on: fun === 'fun_task'}"
            @click.stop="goToRouter({name:'fun_task_index'});"
          >
            <div class="dot" v-show="new_task"></div>
            <div class="icon"></div>
            <!-- <router-link to="fun_task_index" class="icon"></router-link> -->
          </div>
        </el-tooltip>
        <el-tooltip
          content="联系人频道"
          placement="right"
          effect="light"
          popper-class="fun_aside_tooltip"
        >
          <div
            :to="{name:'fun_contact_index'}"
            class="fun fun_contact"
            :class="{on: fun === 'fun_contact'}"
            @click.stop="goToRouter({name:'fun_contact_index'});"
          >
            <!-- <router-link to="fun_contact_index" class="icon"></router-link> -->
            <div class="dot" v-show="new_contact"></div>
            <div class="icon"></div>
          </div>
        </el-tooltip>
        <el-tooltip
          content="我的模板"
          placement="right"
          effect="light"
          popper-class="fun_aside_tooltip"
        >
          <!-- <router-link :to="{name: 'fun_template_index'}"  class="fun fun_template" :class="{on: fun == 'fun_template'}"> -->
          <!--<div-->
          <!--  :to="{name: 'fun_template_shopping_mail'}"-->
          <!--  class="fun fun_template"-->
          <!--  :class="{on: fun == 'fun_template'}"-->
          <!--  @click.stop="goToShoppingMail({name: 'fun_template_shopping_mail'})"-->
          <!--&gt;-->
          <div
            :to="{name: 'fun_template_shopping_mail'}"
            class="fun fun_template"
            :class="{on: fun == 'fun_template'}"
            @click.stop="goToRouter({name: 'fun_template_resource'})"
          >
            <div class="dot" v-show="new_template"></div>
            <div class="icon"></div>
          </div>
        </el-tooltip>
        <el-tooltip
          v-if="arPermission"
          content="元基建"
          placement="right"
          effect="light"
          popper-class="fun_aside_tooltip"
        >
          <!-- <router-link :to="{name: 'fun_template_index'}"  class="fun fun_template" :class="{on: fun == 'fun_template'}"> -->
          <div
            :to="{name: 'fun_argumented_reality'}"
            class="fun fun_argumented_reality"
            :class="{on: fun === 'fun_argumented_reality'}"
            @click.stop="goToRouter({name: 'fun_argumented_reality_index'})"
          >
            <div
              v-show="new_template && 0"
              class="dot"
            ></div>
            <div class="icon"></div>
          </div>
        </el-tooltip>
      </div>
    </div>
    <div class="bottom_group">
      <div class="avatar_group" @click="$tools.routeTo({name: 'personal_center_index'})">
        <img v-if="user" :src="`${$avatar_url}${user.thumbnail}`" alt="" class="avatar cp">
      </div>

    </div>

  </div >
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
export default {
  data(){
    return {
      fun: '',
      // user: this.$tools.getCookie('user'),
    }
  },
  methods:{
    /**
     * 判断当前路由
     */
    judgeFun(path){
      if(path.indexOf('fun_task') != -1){
        this.fun = 'fun_task';
        return;
      }
      if(path.indexOf('fun_contact') != -1){
        this.fun = 'fun_contact';
        return;
      }
      if(path.indexOf('fun_template') != -1){
        this.fun = 'fun_template';
        return;
      }
      // if (path.indexOf('fun_augmented_reality') !== -1) {
      //   this.fun = 'fun_augmented_reality'
      //   return
      // }
      if (path.indexOf('funArgumentedReality') !== -1) {
        this.fun = 'fun_argumented_reality'
        return
      }
      this.fun = ''
    },
    /**
     * @description: 获取是否有新消息
     * @param {*}
     * @return {*} 全部为0 则返回false[没有新消息]
     */
    getNewTask(){
      const {message_box_list} = this.$store.state;
      if(message_box_list && message_box_list.length){
        const result =  message_box_list.every(item=> {
          // 全部为0 则返回false
          return item.unreadMessageNum == 0 || !item.unreadMessageNum;
        });
        return !result;
      }
      return false;

    },
    goToShoppingMail(params){
      this.$store.commit('set_spm_nav', undefined); //清空nav
      this.$nextTick(function(){
        this.goToRouter(params);
      })
    },
    goToRouter(params){
      const { fun } = this
      const { name } = params
      // console.log(name)
      if (name === 'fun_argumented_reality_index') {
        this.$router.push({ name })
        return
      }
      if ((fun === 'fun_task') && (name !== 'fun_task_index')) {
        store.commit('set_taskChannelRouter', this.$route)
      }
      // console.log(this.$route)
      if ((fun !== 'fun_task') && (name === 'fun_task_index')) {
        const { taskChannelRouter } = store.state
        if (taskChannelRouter) {
          this.$router.push({
            name: taskChannelRouter.name,
            params: taskChannelRouter.params,
            query: taskChannelRouter.query
          })
          return
        }
      }

      this.$router.push({name})

    }
  },
  mounted(){
    this.judgeFun(this.$route.path);
    this.$store.commit('set_user', this.$tools.getCookie('user'))
    // console.log(this.user)
  },
  computed:{
    ...mapState({
      user: state => state.user,
      arPermission: state => state.app.arPermission,
    }),
    showAr () {
      if (this.user) {
        return this.user.areditflag
      }
      return false
    },
    new_task () {
      // return this.$store.state.new_task;
      return this.getNewTask()
    },
    new_contact () {
      return this.$store.state.new_contact
    },
    new_template () {
      return this.$store.state.new_template
    },
    user () {
      return this.$store.state.user
    },
  },
  watch:{
    '$route.path': function (newVal, oldVal) {
      this.$store.commit('common_set_search_switch', false); // 清空搜索
      this.judgeFun(newVal);
    },
    /** 在当前页暂不提示红点 */
    new_task: function (val) {
      if (val && this.fun == 'fun_task') {
        this.$store.commit('set_new_task', false); //移除红点
      }
    },
    new_contact: function(val){
      if(val && this.fun == 'fun_contact'){
        this.$store.commit('set_new_contact', false); //移除红点
      }
    },
    new_template: function(val){
      if(val && this.fun == 'fun_template'){
        this.$store.commit('set_new_template', false); //移除红点
      }
    },
    user: {
      handler: function(){},
      deep: true,
    }
  },
}
</script>

<style lang="scss" scoped>
@mixin fun_icon($name){
  .fun_#{$name}{
    .icon{
      background-image: url(~@/assets/images/common/fun_#{$name}.png);
    }
    &.on{
      .icon{
        background-image: url(~@/assets/images/common/fun_#{$name}_on.png);
      }
    }
  }
}
.default_fun_aside{
  @include flexsb;
  flex-direction: column;
  width:100%;
  height: 100%;
  background-color: $main_blue;
  .top_group{
    @include flex;
    flex-direction: column;
    padding-top: 6px;
    width:100%;
    .logo_group{
      @include flex;
      justify-content: center;
      box-shadow:0px 0px 5px 0px rgba(255,255,255,0.2);
      width: 48px;
      height: 48px;
      .logo{
        width:48px;
        height:48px;
      }
    }
    .fun_group{
      @include flex;
      flex-direction: column;
      padding-top:30px;
      width:100%;
      .fun{
        @include transition;
        @include flex;
        position: relative;
        cursor: pointer;
        margin-bottom: 24px;
        justify-content: center;
        height:50px;
        width:100%;
        background-color: transparent;
        &.on{
          background-color: #fff;
          &:hover{
            .icon{
              opacity: 1;
            }
          }
        }
        &:hover{
          .icon{
            opacity: 0.8;
          }
        }
        .dot{
          position: absolute;
          top: 10px;
          left: 14px;
          border-radius: 50%;
          background-color: $red_dot;
          width: 10px;
          height: 10px;
          z-index: 2;
        }
        .icon{
          @include transition;
          width:24px;
          height: 24px;
          background-size: 100%;
        }
      }
      @include fun_icon(task);
      @include fun_icon(contact);
      @include fun_icon(template);
      @include fun_icon(argumented_reality);
    }
  }
  .bottom_group{
    @include bbox;
    @include flex;
    justify-content: center;
    margin-bottom: 30px;
    width:46px;
    height:46px;
    background:rgba(255,255,255,1);
    border-radius:4px;
    .avatar_group{
      width:44px;
      height:44px;
    }
    .avatar{
      width:44px;
      height:44px;
      display: block;
      border-radius:4px;
    }
  }
}
</style>

<style>
.fun_aside_tooltip{
  /* background:red!important; */
  border:1px solid transparent!important;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.6);
}
.fun_aside_tooltip .popper__arrow{
  border: none;
}
</style>
