import request from '@/api/request'
const ar = {
  state: {
    sceneList: [],
    scenePage: undefined,
    hasMoreScenePage: true,
    pageType: 'scene',
    assetList: [],
    sceneData: undefined,
    sdkEditor: undefined,
    editSceneData: undefined,
  },
  mutations: {
    setArEditSceneData (state, data) {
      state.editSceneData = data
    },
    setArSdkEditor (state, editor) {
      state.sdkEditor = editor
    },
    setArSceneData (state, scene) {
      state.sceneData = scene
    },
    setArAssetList (state, list) {
      state.assetList = list
    },
    clearAr (state) {
      state.sceneList = []
      state.scenePage = undefined
      state.hasMoreScenePage = true
      state.pageType = 'scene'
    },
    setArPageType (state, type) {
      state.pageType = type
    },
    setArSceneList (state, list) {
      state.sceneList = list
    },
    setArScenePage (state, page) {
      state.scenePage = page
    },
    clearArScene (state) {
      // console.log('clearArScene')
      state.sceneList = []
      state.scenePage = undefined
      state.hasMoreScenePage = true
    }
  },
  actions: {
    /**
     * 获取场景
     * @param context
     * @param params
     * @returns {Promise<*>}
     */
    async getARSceneList (context, params) {
      // console.log(context, params)
      const res = await request.request(
        '/vr/myModelList',
        {
          pn: params.pn
        }
      )
      // if (res.message === 'success') {
      //   context.commit('setArSceneList', res.contents.page.result)
      //   context.commit('setArScenePage', res.contents.page)
      // }
      return res
    },
    /**
     * 获取资产
     * @param context
     * @param params
     * @returns {Promise<{}|*>}
     */
    async getARAssetList (context, params) {
      // console.log(params)
      try {
        const res = await request.request(
          '/vr/myResourceList',
          {
            type: params.type,
            pn: params.pn,
            packageId: params.packageId
          }
        )
        return res
      } catch (e) {
        // console.log(e)
        return {}
      }
    },
    /**
     * 获取方案
     * @param content
     * @param params
     * @returns {Promise<{}|*>}
     */
    async getArSchemeList (content, params) {
      try {
        const res = await request.request(
          '/vr/myPlanList',
          {
            status: params.status,
            pn: params.pn,
          }
        )
        return res
      } catch (e) {
        // console.log(e)
        return {}
      }
    },
    async getSchemeBySceneId (content, params) {
      try {
        const res = await request.request(
          '/vr/myPlanListByModel',
          {
            status: params.status,
            modelId: params.modelId,
            pn: params.pn,
          }
        )
        return res
      } catch (e) {
        // console.log(e)
        return {}
      }
    },
    async createArScheme () {
      try {
        const res = await request.request(
          '/vr/createPlan',
          {
            name: '方案5-3',
            pic: 'pmcs/test/2024/04/07/1712476231644hojsuz.jpg',
            modelId: 5,
          }
        )
        return res
      } catch (e) {
        // console.log(e)
        return {}
      }
    }
  }
}

export default ar
