import { updateModel } from '@/models'
import vue from '@/main';
class PMessageBox{
  chatFlag
  chatId
  chatType
  isdisturb
  islinkme
  istop
  lastSendTime
  masterUserid
  messageContent
  messageContentType
  name
  photo
  singleusername
  thumbnail
  unreadMessageNum
  updateUserId
  updateids
  updateusername

  constructor(params){
    // const {
    //   chatId,
    //   chatType,
    //   isdisturb,
    //   istop,
    //   lastSendTime,
    //   masterUserid,
    //   messageContent,
    //   messageContentType,
    //   name,
    //   photo,
    //   unreadMessageNum,
    //   updateUserId,
    //   updateusername,
    //   islinkme,
    // } = params || {};
    updateModel(params, this)

    // this.chatId             = chatId;
    // this.chatType           = chatType;
    // this.isdisturb          = isdisturb;
    // this.istop              = istop;
    // this.lastSendTime       = lastSendTime;
    // this.masterUserid       = masterUserid;
    // this.messageContent     = messageContent;
    // this.messageContentType = messageContentType;
    // this.name               = name;
    // this.photo              = photo;
    // this.unreadMessageNum   = unreadMessageNum;
    // this.updateUserId       = updateUserId;
    // this.updateusername     = updateusername;
    // this.islinkme           = islinkme;
  }
  changeDoNotDistrub = async ()=>{
    // const { chatId, chatType, isdisturb} = this;
    const res = await vue.$api.dialog.changeDoNotDistrub(Object.assign(
      {},
      this,
      {isdisturb: this.isdisturb ? 0 : 1}
    ));
    if(res.message === 'success'){
      return;
    }else{
      this.isdisturb = this.isdisturb === 1 ? 0 : 1;
    }
  }

  viewMessageContent = () => {
    const {
      islinkme,
      isdisturb,
      unreadMessageNum,
      updateusername,
      messageContent
    } = this
    let html = '';
    if(islinkme){
      html += '<span style="color:#FF3B30;">[有人@我]</span> '
    }
    if(isdisturb && unreadMessageNum){
      html += `[${unread(unreadMessageNum)}条] ` // 未读数量
    }
    if(updateusername){
      html += `${ updateusername } ` // 更新者名字
    }
    html += messageContent;
    return html;
  }
}

function unread(num){
  if(num>99){
    return '99+';
  }
  return num;
}

export default PMessageBox;
