<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),

    src() {
      const {
        resourceFileData
      } = this
      const {
        file,
      } = resourceFileData
      const {
        url
      } = file
      return `${ this.$file_url }${ url }`
    }
  }
}
</script>

<template>
  <div
    class="v_resource_center_detail_type_audio d-flex justify-center"
  >
    <div
      class="microsoft_file d-flex justify-center"
    >
      <div
        class="img_wrapper d-flex justify-center"
      >
        <img
          :src="src"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v_resource_center_detail_type_audio {
  width: 100%;
  .microsoft_file {
    width: 100%;
    max-width: 1000px;
    .img_wrapper {
      width: 100%;
      img {
        max-width: 90%;
      }
    }
  }
}
</style>
