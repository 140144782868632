<template>
  <div
    class="components_task_side_bar_teach_pages_make_file_page bbox d-flex  align-center"
  >

    <div class="wrapper d-flex align-center cp"
      v-if="0"
      @click="previewFile()"
    >
      <div class="icon flex-shrink-0"
        :class="[$tools.getExtName(file.filename)]"
      ></div>
      <div class="name">{{file.filename}}</div>
      <div class="progress"
        v-if="file.progress && !file.url"
        :style="{width: `${file.progress || 0}%`}"
      ></div>
    </div>
    <widget-file-unit
      :file="file"
      :editable="edit"
      :show_icon="false"
      class="file_wrapper"
      @click.native="previewFile"
      @handleUpload="reUpload"
    ></widget-file-unit>
    <div class="delete button"
      @click="deleteFile();"
      v-if="edit"
    ></div>
    <div class="button download"
      @click="downloadFile();"
      v-if="use && !edit"
    ></div>
    <!-- <div class="cancel button"></div> -->
  </div>
</template>
<script>
import oss from '@/api/modules/oss'
import WidgetFileUnit from '@/components/Task/Main/Widget/Content/Part/File.vue';
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },

  },
  components: {
    WidgetFileUnit,
  },
  mounted () {
    // console.log(this.edit, this.use)
    // console.log(this.use && !this.edit)
  },
  data(){
    return {

    }
  },
  computed:{
    file(){
      const {item} = this;
      const {file} = item;
      return file;
    }
  },
  methods: {
    previewFile(){
      const {preview} = this;
      if(preview){
        this.$notice({desc: '请先购买教学内容'});
        return;
      }
      this.$emit('handlePreview', this.item);
    },
    async downloadFile(){
      const {file} = this;
      oss.downloadFile({ file })
      return
      // this.$api.download.file({file});
      // return;
      let {id, filetype, createTime} = file;
      // const critical = this.$moment('2021-09-08 10:56').format('x'); // 临界时间 // 以发布日期为准
      // const critical = this.$critical;
      // const picture_critical = this.$picture_critical;
      // let type = filetype == 'pic' ? 2 : 2;
      // if(createTime < critical && createTime > picture_critical){
      //   type = 2;
      // }
      let type = 2;
      if(id){
        let exportURL = `https://${this.$domain_name}/project/download?fileid=${id}&type=${type}`;

        // iframe下载
        const iframe = document.createElement("iframe");
        iframe.style.display = "none"; // 防止影响页面
        iframe.style.height = 0; // 防止影响页面
        iframe.src = exportURL;
        // console.log(exportURL);
        // return
        document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
        // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
        setTimeout(()=>{
          iframe.remove();
        }, 5 * 60 * 1000);
      }
    },
    deleteFile(){
      const {item} = this;
      this.$emit('handleDelete', item);
    },
    reUpload(){
      this.$emit('handleReUpload', this.item);
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin file_icon($type){
  &.#{$type}{
    background-image: url(~@/assets/images/task/#{$type}.png);
  }
}
.components_task_side_bar_teach_pages_make_file_page{
  @include transition;
  width: 100%;
  padding: 5px 10px;
  // height: 32px;
  position: relative;
  z-index: 2;
  &:hover{
    background-color: #f6f6f6;
  }
  .file_wrapper{
    padding-right: 40px;
  }

  .wrapper{
    width: 100%;
    overflow: auto;
    position: relative;
    height: 100%;
    .progress{
      @include transition;
      position: absolute;
      bottom: 0;
      left: 0;
      // width: 60%;
      height: 2px;
      background-color: $main_blue;
    }
  }
  .icon{
    @include background;
    background-image: url(~@/assets/images/task/unknown.png);
    width: 18px;
    height: 18px;
    margin-right: 8px;
    @include file_icon(doc);
    @include file_icon(xls);
    @include file_icon(ppt);
    @include file_icon(mp4);
    @include file_icon(pic);
    @include file_icon(zip);
    @include file_icon(pdf);
  }
  .name{
    @include single_line_intercept;
    flex: 1;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
  &:hover{
    .button{
      opacity: 1;
    }
  }
  .button{
    @include transition;
    opacity: 0;
    position: absolute;
    top:0;
    right: 0;
    z-index: 3;
    background-color: #eee;
    width: 32px;
    height: 100%;
    &.delete{
      @include background(15px,15px);
      background-image: url(~@/assets/images/teach/delete.png);
    }
    &.cancel{
      @include background(12px,12px);
      background-image: url(~@/assets/images/teach/cancel.png);
    }
    &.download{
      @include background(12px,12px);
      background-image: url(~@/assets/images/teach/download.png);
    }
  }

}
</style>
