<template>
  <div
    class="components_task_container_page d-flex flex-column .slide-out-blurred-right"
    :style="{
      'max-width':max_width,
      'max-height': max_height,
      'box-shadow': box_shadow,
      'border': border,
    }"
  >
    <fun-bar
      v-if="!sell"
      class="fun_bar flex-shrink-0"
      :task="task"
      :editable="editable"
      :view="view"
      :vip="vip"
      :system="system"
      :recycle="recycle"
      @handleCall="callTask"
      @handleUse="useTask"
      @handleSend="handleSend"
      @handleRecover="handleRecover"
      @handleCompleteDelete="handleCompleteDelete"
    ></fun-bar>
    <div class="d-flex main_group align-stretch">
      <Main
        :select_target="select_target"
        class="main"
        :editable="editable"
        :view="view"
        :system="system"
        :recycle="recycle"
        :sell="sell"
        :shopping_mail="shopping_mail"
        ref="main"
        @handleCall="callTask"
        @handleSortFile="handleSortFile"
      ></Main>
      <side-bar
        v-if="showSideBar"
        class="flex-shrink-0 side_bar"
        :type="sidebar_type"
        :editable="editable"
        :sell="sell"
        :system="system"
        :recycle="recycle"
        :show_intro="show_intro"
        :shopping_mail="shopping_mail"
        @handleChangePannel="changePannel"
        @handleAddNewWidget="addNewWidget"
        @handleSwitchSidebar="switchSidebar"
        @handleSavaTask="handleSavaTask"
      ></side-bar>
    </div>
    <file-sorting
      v-if="switch_file_sorting"
      @handleClose="closeFileSorting"
      @handleSure="sureFileSorting"
      :list="file_sorting_list"
      :widget="file_sorting_widget"
      :parent="file_sorting_parent"
    ></file-sorting>


    <at-pop-up
      v-if="switch_at"
      :chat_type="Number(chat_type)"
      :chat_id="Number(chat_id)"
      :task_id="Number(task_id)"
      @handleClose="closeAt"
      @handleSure="addAt"
      @handleAll="atAll"
      title="从任务参与人中选择@对象11"
    ></at-pop-up>
  </div>
</template>

<script>
import FunBar from './FunBar.vue';
import Main from './Main.vue';
import SideBar from './SideBar.vue';
import FileSorting from '@/components/PopUp/FileSorting/index.vue';
// import {PFile} from '@/types';
import AtPopUp from '@/components/PopUp/At/index.vue';
import store from '@/store'
export default {
  props: {
    max_width: {
      type: String,
      default: '80%'
    },
    max_height: {
      type: String,
      default: '90%'
    },
    box_shadow: {
      type: String,
      default: 'box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1)',
    },
    border: {
      type: String,
      default: 'none',
    },
    sell: {
      type: Boolean,
      default: false,
    },
    show_intro: {
      type: Boolean,
      default: true,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
    showSideBar: { // 是否显示侧边栏
      type: Boolean,
      default: true
    },
  },
  components: {
    FunBar,
    Main,
    SideBar,
    FileSorting,
    AtPopUp,
  },
  data(){
    return{
      sidebar_type             : 'widget',
      vip                      : false,
      switch_file_sorting      : false,
      file_sorting_parent      : null,
      file_sorting_widget      : null,
      file_sorting_list        : null,
      file_sorting_filesort_arr: null,
    }
  },
  computed: {
    auditMode () {
      return store.state.task_config.auditMode
    },
    task(){
      // console.log(this.$store.state.task)
      return this.$store.state.task;
    },
    task_config(){
      return this.$store.state.task_config
    },
    select_target(){
      return this.$store.state.task_config.select_target;
    },
    editable(){
      const { sell, auditMode } = this
      const { task_config } = this.$store.state
      const { editable } = task_config
      if (auditMode) {
        return false
      }
      if (sell) {
        return false
      }
      return editable
    },
    view(){
      const {task_config} = this.$store.state;
      const {view} = task_config;
      return view;
    },
    system(){
      const {task_config} = this.$store.state;
      const {system} = task_config;
      // // console.log(task_config)
      return system;
    },
    recycle(){
      const {task_config} = this.$store.state;
      const {recycle} = task_config;
      return recycle;
    },

    // 任务聊天调用数据
    task_id(){
      const {task} = this.$store.state;
      return task.task.id;
    },
    chat_type(){
      const {task_config} = this.$store.state;
      return task_config.chat_type;
    },
    chat_id(){
      const {task_config} = this.$store.state;
      return task_config.chat_id;
    },
    task_chat_at_data(){
      const {task_chat_at_data} = this.$store.state;
      return task_chat_at_data;
    },
    switch_at(){
      // return false
      const { task_chat_at_data } = this
      if (!task_chat_at_data) {
        return false
      }
      return Boolean(task_chat_at_data.switch_at)
    }

  },
  methods: {
    changePannel(type){
      this.sidebar_type = type;
    },
    addNewWidget(){
      this.$refs.main.addNewWidget();
    },
    switchSidebar(){
      this.$refs.main.switchSidebar();
    },
    callTask(params){
      const { taskId, config } = params
      const newConfig = Object.assign(
        {},
        {
          // open_by_call: true,
          // select_target: true,
          open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
          open_with_call_page: false,
          jump: true,
          use: true,
        },
        config || {}
      )

      this.$api.moduleTask.callTask({
        taskId,
        config: newConfig
      })
    },
    useTask(taskId){
      this.$api.moduleTask.callTask({taskId, config: {
        select_target: true,
        jump: true,
        use: true,
        open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
        open_with_call_page: false,
      }});
    },
    handleSavaTask () {
      this.handleSend()
    },
    async handleSend () {
      const { task, task_config, task_unsent_message } = this.$store.state
      if(task_unsent_message){
        this.$openConfirm({
          title: '讨论区有内容未发出，是否发布讨论区内容？',
          desc: '',
          sure:'发布',
          cancel:'我去看看',
          sureType: '', // 确定按钮样式 '','warn'
          onSure:async ()=>{
            const {draft, call} = task_config;
            if(draft){
              await this.$api.moduleTask.sendDraft();
              return;
            }
            if(task.task.taskType == 2){
              await this.$api.moduleTask.createInstance();
              return;
            }
            // console.log(call, 1)
            if(task.task.taskType == 3){
              if(call){
                await this.$api.moduleTask.createInstance({config: {select_target: false}}); // 不重新选择对象
              }else{
                await this.$api.moduleTask.editInstance();
              }
              return;
            }
            // 通过调用打开 再发布前增加创建标记
            // if(call){
            //   task_config.create = true;
            // }
            await this.$api.moduleTask.sendTask();
            return;
          },
          onCancel:()=>{
            this.sidebar_type = 'chat';
          },
        })
        return;
      }
      const { draft, call } = task_config
      if (draft) {
        await this.$api.moduleTask.sendDraft();
        return;
      }
      if (Number(task.task.taskType) === 2) {
        await this.$api.moduleTask.createInstance();
        return;
      }
      // console.log(call, 1)
      if (Number(task.task.taskType) === 3) {
        if(call){
          await this.$api.moduleTask.createInstance({config: {select_target: task_config.call ? false : task_config.select_target}}); // 调用时不重新选择对象
          // await this.$api.moduleTask.createInstance({config: {select_target: false}}); // 不重新选择对象
        }else{
          await this.$api.moduleTask.editInstance();
        }
        return;
      }
      // 通过调用打开 再发布前增加创建标记
      // if(call){
      //   task_config.create = true;
      // }
      await this.$api.moduleTask.sendTask()
    },
    handleRecover(){
      this.$api.recycle_bin.recover(this.task.task);
    },
    handleCompleteDelete(){
      this.$api.recycle_bin.completeDelete({id: this.task.task.id});
    },
    handleSortFile(params){
      let file_sorting_filesort_arr = [];
      const {widget, parent} = params;
      // console.log(params);
      this.$set(this, 'file_sorting_parent', parent);
      this.$set(this, 'file_sorting_widget', widget);
      let file_sorting_list = widget.fileList;
      file_sorting_list.forEach((item, index)=>{
        // file_sorting_list[index] = new PFile(item);
        file_sorting_list[index] = item;
        file_sorting_filesort_arr.push(file_sorting_list[index].filesort);
      })
      this.$set(this, 'file_sorting_list', [
        ...new Set([
          ...file_sorting_list
        ])
      ]);
      this.$set(this, 'file_sorting_filesort_arr', [
        ...new Set([
          ...file_sorting_filesort_arr
        ])
      ]);


      this.openFileSorting();
    },
    openFileSorting(){
      this.switch_file_sorting = true;
    },
    closeFileSorting(){
      this.switch_file_sorting = false;
    },
    sureFileSorting(params){
      // // console.log(params);
      const {parent, widget, list, modified} = params;
      const {file_sorting_filesort_arr} = this;
      const {fileList} = widget;
      // // console.log(fileList, list, file_sorting_filesort_arr);
      let filesort = 0;
      let sort_list = [
        ...new Set([
          ...list
        ])
      ];
      sort_list.forEach((item,index) => {
        const {type, id, sorting} = item;
        if(Number(type) !== 1){
          // if(id ){
          //   if(sorting){
          //     item.type = 2;
          //   }
          // }else{
          //   item.type = 0;
          // }
          if(!id){
            item.type = 0;
          }
          delete item.sorting;
          // // console.log(item.filesort, file_sorting_filesort_arr[index])
          if(item.filesort !== file_sorting_filesort_arr[index] && id){
            item.type = 2;
          }
          item.filesort = file_sorting_filesort_arr[index];
          // item.filesort = sort_list.length - index;
        }
      })

      if(parent){
        this.$set(parent, 'modified', modified);
      }
      // // console.log(sort_list);
      this.$set(widget, 'fileList', sort_list);
      this.$set(widget, 'modified', modified);
      this.closeFileSorting();
    },
    closeAt(){
      this.$store.commit('set_task_chat_at_data', Object.assign(
        {},
        this.task_chat_at_data,
        {switch_at:false}
      ));
      return;
    },
    addAt(item){
      // return ;
      const atStr = this.$api.at.atStr(item.atName());
      this.$store.commit('set_task_chat_at_callback', Object.assign(
        {},
        {atStr},
      ))
      this.closeAt();

    },
    atAll(){
      // return ;
      const atStr = this.$api.at.atAll();
      this.$store.commit('set_task_chat_at_callback', Object.assign(
        {},
        {atStr},
      ))
      this.closeAt();
      return;
    },
  },
  mounted(){
    const {task_config, task} = this.$store.state;
    const {taskType} = task.task;
    const {chat} = task_config;
    const already_vip = this.$api.user.membershipPrivileges({notice: false});
    this.vip = already_vip;
    if(taskType == 2 || taskType === 3 ){
      this.sidebar_type = 'teach';
    }
    if(chat){
      this.sidebar_type = 'chat';
    }

    // console.log(this.editable, task_config, task_config.view, task)
  }
}
</script>

<style lang="scss" scoped>
.slide-out-blurred-right {
  -webkit-animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
  animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}
.slide-in-blurred-right {
	-webkit-animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
.components_task_container_page{
  width: 100%;
  height: 100%;
  max-width: 80%;
  // min-width: 1000px;
  // max-height: 80%;
  max-height: 90%;
  // min-height: 800px;
  border-radius: 4px;

  background-color:#fff;
  .main_group{
    width: 100%;
    height: 100%;
    flex: 1;
    background-color: #f8f8f8;
    overflow: hidden;
  }
  .main{
    flex:1;
  }
  .side_bar{
    border-left: 2px solid #f4f4f4;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-13 15:43:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
            transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
            transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-13 15:50:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}



</style>
