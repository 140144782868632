<script>
export default {
  props: {
    coursePackage: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    // console.log(this.coursePackage)
  },
  methods: {
    clickCoursePackage () {
      this.$emit('clickCoursePackage', this.coursePackage)
    }
  }
}
</script>

<template>
  <div
    class="course_wrapper cp v_resource_course_center_course_thumbnail"
    @click="clickCoursePackage(item)"
  >
    <div
      class="img_group"
    >
      <div
        class="img"
        :style="{
          backgroundImage: `url(${ $file_url }${ coursePackage.webpic })`
        }"
      ></div>
      <div
        class="img img_url"
        :style="{
          backgroundImage: `url(${ $img_url }${ coursePackage.webpic })`
        }"
      ></div>
    </div>
    <div
      class="info_group"
    >
      <div
        class="name"
      >
        {{ coursePackage.title }}
      </div>
      <div
        class="info"
      >
        {{ coursePackage.ownedEnterprises }}｜{{ coursePackage.userName }}
      </div>
    </div>
    <div
      class="fun_group d-flex align-center justify-end"
    >

      <!-- 学习量 -->
      <div
        class="icon user"
      ></div>
      <div
        class="num"
      >
        {{ coursePackage.studyCount || 0 }}
      </div>
      <div
        class="icon zan"
      ></div>
      <div
        class="num"
      >
        {{ coursePackage.likeCount || 0 }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v_resource_course_center_course_thumbnail {
  @include transition;
  width: 288px;
  height: 280px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
  border-radius: 16px;
  border: 1px solid #E8EEFF;
  &:hover {
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
  }
  .img_group {
    width: 288px;
    height: 162px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    position: relative;
    .img {
      @include background;
      width: 100%;
      height: 100%;
      background-size: cover;
      z-index: 2;
      &.img_url {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
      }
    }
  }
  .info_group {
    width: 100%;
    height: 74px;
    padding: 0 16px;
    border-bottom: 2px solid #F8F8F8;
    .name {
      @include single_line_intercept;
      padding-top: 16px;
      padding-bottom: 4px;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      text-align: justify;
      font-style: normal;
      text-transform: uppercase;
    }
    .info {
      @include single_line_intercept;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
    }
  }
  .fun_group {
    height: 40px;
    width: 100%;
    padding: 0 16px;
    .icon {
      width: 14px;
      height: 14px;
      margin-right: 5px;
      margin-left: 16px;
      &.user {
        background-image: url(~@/assets/images/resource/course_user.png);
      }
      &.zan {
        background-image: url(~@/assets/images/resource/course_zan.png);
      }
    }
    .num {
      font-weight: 400;
      font-size: 12px;
      color: #AAAAAA;
      line-height: 17px;
      text-align: right;
      font-style: normal;
    }
  }
}
</style>
