<script>
import store from '@/store'
import { mapState } from 'vuex'
import { listForClasses } from '@/classes'
import ARScene from '@/classes/modules/AR/ARScene'
import ARScheme from '@/classes/modules/AR/ARScheme'
import JsModal from '@/components/JsModal'

export default {
  components: {
    JsModal,
  },
  data () {
    return {
      loading: false,
      page: undefined,
      hasMore: false,
      changeEditorDescribe: '当前场景未保存, 是否切换?',
      changeSceneData: undefined,
    }
  },
  computed: {
    ...mapState({
      sceneList: state => state.ar.sceneList,
      sdkEditor: state => state.ar.sdkEditor,
      editSceneData: state => state.ar.editSceneData,
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      store.commit('clearArScene')
      this.getSceneList()
    },
    async getSceneList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const data = {
        pn: 1
      }
      if (this.page && this.page.pageNo) {
        data.pn = this.page.pageNo + 1
      }
      const res = await store.dispatch(
        'getARSceneList',
        data
      )
      // console.log(da)
      this.loading = false
      if (res.message === 'success') {
        this.$set(
          this,
          'page',
          res.contents.page
        )
        if (res.contents.page.result && res.contents.page.result.length === 20) {
          this.hasMore = true
        } else {
          this.hasMore = false
        }
        // if (this.sceneList && this.sceneList) {
        const sceneList = [
          ...this.sceneList,
          ...listForClasses({
            list: res.contents.page.result,
            classes: ARScene
          })
        ]
        store.commit('setArSceneList', sceneList)
        // }
      }
    },
    loadMore () {
      this.getSceneList()
    },
    clearPage () {
      this.$set(
        this,
        'page',
        undefined
      )
    },
    async touchScene (scene) {
      if (!this.sdkEditor) {
        this.$notice({
          desc: `编辑器未加载完, 请稍等`,
          type: 'error'
        })
        return
      }
      // console.log(scene)
      // console.log(this.sdkEditor)
      if (this.editSceneData) {
        // const saveData = await this.sdkEditor.project.saveProject()
        // console.log(saveData)
        // console.log(this.editSceneData.scheme.plancode)
        // console.log(saveData === this.editSceneData.scheme.plancode)
        // if (saveData !== this.editSceneData.scheme.plancode) {
        //   this.$set(
        //     this,
        //     'changeSceneData',
        //     scene
        //   )
        //   this.$modal.show('changeArEditor')
        //   return
        // }
        if (!this.editSceneData.save) {
          this.$set(
            this,
            'changeSceneData',
            scene
          )
          this.$modal.show('changeArEditor')
          return
        }
      }
      store.commit(
        'setArEditSceneData',
        {
          scene,
          scheme: new ARScheme({
            modelId: scene.id,
          }),
          save: false,
        }
      )
      this.loading = true
      await this.sdkEditor.project.initProject()
      await this.sdkEditor.scenes.addScene()
      console.log({ id: scene.fid })
      console.log({ resUrl: scene.resUrl })
      await this.sdkEditor.loader.loadVpsBaseboardByUrl(
        // scene.resUuid,
        scene.fid,
        // '',
        scene.resUrl

      )
      this.loading = false
    },
    async doChangeScene () {
      const scene = this.changeSceneData
      store.commit(
        'setArEditSceneData',
        {
          scene,
          scheme: new ARScheme({
            modelId: scene.id,
          }),
          save: false,
        }
      )
      this.loading = true
      await this.sdkEditor.loader.loadVpsBaseboardByUrl(
        scene.fid,
        scene.resUrl
      )
      this.loading = false
      this.$modal.hide('changeArEditor')
    }
  }
}
</script>
<template>
<div
  v-loading="loading"
  class="v_ar_scene_list"
>
  <template
    v-for="(item, index) in sceneList"
  >
    <el-tooltip
      :key="index"
      class="item"
      effect="dark"
      :content="item.name"
      placement="bottom"
      :open-delay="2000"
    >
      <div
        class="scene_group cp"
        :class="{
          active: editSceneData && editSceneData.scene && editSceneData.scene.id === item.id
        }"
        @click.stop="touchScene(item)"
      >
        <div
          v-if="item.pic.includes('http')"
          class="bg"
          :style="{
            backgroundImage: `url(${ item.pic })`,
          }"
        ></div>
        <div
          v-else
          class="bg"
          :style="{
            backgroundImage: `url(${ $file_url }${ item.pic })`,
          }"
        ></div>
        <div
          class="name_group bbox"
        >
          <div
            class="name"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          class="hover_grouo"
        >
          选择
        </div>
      </div>

    </el-tooltip>
  </template>
  <div
    v-if="hasMore"
    class="more_group cp"
    @click.stop="loadMore"
  >
    加载更多
  </div>
  <modal
    name="changeArEditor"
    height="auto"
    width="300px"
    :clickToClose="false"
  >
    <JsModal
      :title="'提示'"
      @closeHandler="$modal.hide('changeArEditor')"
    >
      <div
        class="change_ar_editor_group"
      >
        <div
          class="describe"
        >
          {{ changeEditorDescribe }}
        </div>
        <div
          class="fun_group d-flex align-center justify-center"
        >
          <div
            class="btn btn_light"
            @click.stop="$modal.hide('changeArEditor')"
          >
            否
          </div>
          <div
            class="btn btn_dark"
            @click.stop="doChangeScene"
          >
            是
          </div>
        </div>
      </div>
    </JsModal>
  </modal>
</div>


</template>

<style scoped lang="scss">
.v_ar_scene_list {
  @include scrollbar;
  width: 100%;
  height: 100%;
  overflow: auto;
  .scene_group {
    box-sizing: border-box;
    width: 100%;
    height: 135px;
    border-radius: 2px;
    margin: 5px 0;
    position: relative;
    border: 2px solid transparent;
    &.active {
      border: 2px solid #FF7500;
    }
    &:hover {
      .hover_grouo {
        opacity: 1;
      }
    }
    .bg {
      width: 100%;
      height: 100%;
      //background-color: #ccc;
      background: {
        size: contain;
        position: center;
      }
      border-radius: 4px;
      position: relative;
      z-index: 2;
    }
    .name_group {
      position: absolute;
      z-index: 3;
      height: 30px;
      width: 100%;
      bottom: 0;
      left: 0;
      border-radius: 0 0 4px 4px;
      background-color: rgba(0, 0, 0, 0.7);
      padding: 0 15px;
      .name {
        @include single_line_intercept;
        width: 100%;
        color: #fff;
        line-height: 30px;
        font-size: 14px;
      }
    }
    .hover_grouo {
      @include transition;
      width: 100%;
      height: 30px;
      text-align: center;
      border-radius: 0 0 4px 4px;
      line-height: 30px;
      font-size: 14px;
      color: #fff;
      position: absolute;
      z-index: 4;
      background-color: $main_blue;
      bottom: 0;
      left: 0;
      opacity: 0;
    }
  }
  .more_group {
    text-align: center;
    width: 100%;
    padding: 10px 0;
    font-size: 13px;
    color: $main-blue;
  }
  .change_ar_editor_group {
    padding: 20px;
    .describe {
      font-size: 16px;
      text-align: center;
    }
    .fun_group {
      padding-top: 20px;
      .btn {
        font-size: 16px;
        padding: 5px 20px;
        margin: 0 15px;
        border-radius: 2px;
      }
    }
  }
}
</style>
