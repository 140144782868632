import request from '@/api/request'

const collect = {
  state: {
    coursePackageList: undefined,
    resourceFileList: undefined,
  },
  mutations: {
    setCollectCoursePackageList (state, coursePackageList) {
      state.coursePackageList = coursePackageList
    },
    setCollectResourceFileList (state, resourceFileList) {
      state.resourceFileList = resourceFileList
    },
  },
  actions: {
    async getCollectCoursePackageList (context, params) {
      const res = await request.request(
        'projectShop/shopPackages',
        params,
      )
      if (res.message ==='success') {
        context.commit(
          'setCollectCoursePackageList',
          res.contents.shoppackages
        )
      } else {
        context.commit(
         'setCollectCoursePackageList',
          undefined
        )
      }
      return res
    },
    async getCollectResourceFileList (context, params) {
      const res = await request.request(
        'projectShop/shopFiles',
        params,
      )
      if (res.message ==='success') {
        context.commit(
         'setCollectResourceFileList',
          res.contents.shopfiles
        )
      } else {
        context.commit(
        'setCollectResourceFileList',
          undefined
        )
      }
      return res
    }
  }
}

export default collect
