var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "welcome_unit_page" }, [
    _c("div", { staticClass: "time_group" }, [
      _vm._v(
        "\n    " + _vm._s(_vm._f("universalDate")(_vm.item.createTime)) + "\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "unit_content_group cp", class: [_vm.unit_type] },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.unit_type === "left",
                expression: "unit_type==='left'",
              },
            ],
            staticClass: "avatar cp",
          },
          [_c("AvatarDetail", { attrs: { msg_data: _vm.item } })],
          1
        ),
        _c("div", { staticClass: "container_wrapper" }, [
          _vm.unit_type === "left"
            ? _c("div", { staticClass: "user_name" }, [
                _vm._v(_vm._s(_vm.item.userName)),
              ])
            : _vm._e(),
          _vm.is_welcome && 0
            ? _c("div", { staticClass: "container un_sel" }, [
                _c("div", { staticClass: "info_group" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.item.taskName)),
                  ]),
                  _c("div", {
                    staticClass: "description",
                    domProps: {
                      textContent: _vm._s(
                        _vm.item.work
                          ? _vm.item.work
                          : "感谢您使用妙行系统！希望妙行系统可以帮助您把实践任务教学和管理功能合并，以沟通的方式实现用户之间的协同交互。"
                      ),
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn button cp",
                      on: {
                        click: function ($event) {
                          return _vm.doOpen()
                        },
                      },
                    },
                    [_vm._v("查看详情")]
                  ),
                ]),
                !_vm.item.shareUrl
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("@/assets/images/dialog/welcome_placeholder_img.png"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm.item.shareUrl
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: `${_vm.$img_url}${_vm.item.shareUrl}`,
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.is_welcome
            ? _c("div", { staticClass: "container un_sel" }, [
                _c("div", { staticClass: "info_group" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.item.taskName)),
                  ]),
                  _c("div", {
                    staticClass: "description",
                    domProps: {
                      textContent: _vm._s(
                        _vm.item.work
                          ? _vm.item.work
                          : "感谢您使用妙行系统！希望妙行系统可以帮助您把实践任务教学和管理功能合并，以沟通的方式实现用户之间的协同交互。"
                      ),
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn button cp",
                      on: {
                        click: function ($event) {
                          return _vm.handleCheck()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.checkButtonText) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                !_vm.item.shareUrl
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: require("@/assets/images/dialog/welcome_placeholder_img.png"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm.item.shareUrl
                  ? _c("img", {
                      staticClass: "img",
                      attrs: {
                        src: `${_vm.$img_url}${_vm.item.shareUrl}`,
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ])
            : _c(
                "div",
                {
                  staticClass: "container share un_sel",
                  class: { right: _vm.unit_type === "right" },
                  on: {
                    click: function ($event) {
                      return _vm.doOpen()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "descript_group justify-space-between" },
                    [
                      _c("div", { staticClass: "content_group" }, [
                        _vm.item.taskName
                          ? _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(_vm.item.taskName)),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "work" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.work) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm.is_share_package || _vm.shareCourse
                        ? _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/package/share_icon.png"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                      _vm.is_share_task
                        ? _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/task/share_icon.png"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                      _vm.is_share_folder
                        ? _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/collect/share_icon.png"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm.is_share_package
                    ? _c("div", { staticClass: "hint" }, [_vm._v("课程分享")])
                    : _vm._e(),
                  _vm.is_share_task
                    ? _c("div", { staticClass: "hint" }, [_vm._v("任务分享")])
                    : _vm._e(),
                  _vm.is_share_folder
                    ? _c("div", { staticClass: "hint" }, [_vm._v("收藏包分享")])
                    : _vm._e(),
                  _vm.shareCourse
                    ? _c("div", { staticClass: "hint" }, [_vm._v("课程分享")])
                    : _vm._e(),
                ]
              ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.unit_type === "right",
                expression: "unit_type === 'right'",
              },
            ],
            staticClass: "avatar cp",
          },
          [_c("AvatarDetail", { attrs: { msg_data: _vm.item } })],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }