import { updateModel } from '@/models'

class ArFile {
  createAdminName
  createTime
  createUserid
  filename
  filesize
  id
  name
  owntype
  pic
  type
  url
  userId
  ownedEnterprises

  fileType
  typeText
  fileName
  linkUrl


  constructor (params) {
    this.fileType = 'vr'
    this.typeText = '虚拟仿真文件'
    this.updateData(params)
    this.fileName = this.name
    this.ownedEnterprises = '北京优联信驰信息科技有限公司'
    this.userName = '会E人'
  }




  updateData (params) {
    updateModel(params, this)
    const {
      filesize,
    } = params

    // this.getIconType()
    if (filesize) {
      this.formatSize = this.getFormatSize(filesize/1024)
    }


  }

  getIconType () {
    const { filename }  = this
    if (!filename) {
      return ''
    }
    // 设置图表类型
    const fileName = filename;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    let fileType;
    let textType
    switch (fileExtension) {
      case 'zip':
      case 'rar':
        fileType = 'zip';
        textType = '压缩文件';
        break;
      case 'xls':
      case 'xlsx':
        fileType = 'excel';
        textType = 'Excel文件';
        break;
      case 'mp4':
      case 'avi':
      case 'mov':
      case 'wmv':
      case 'flv':
      case 'mkv':
        fileType = 'video'; // 这里只是列举了常见视频后缀，可按需扩充更多
        textType = '视频';
        break;
      case 'pdf':
        fileType = 'pdf';
        textType = 'PDF文件';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        fileType = 'pic'; // 同样列举常见图片后缀，可完善
        textType = '图片';
        break;
      case 'ppt':
      case 'pptx':
        fileType = 'ppt';
        textType = 'PPT文件';
        break;
      case 'txt':
        fileType = 'txt';
        textType = '文本';
        break;
      case 'doc':
      case 'docx':
        fileType = 'word';
        textType = 'WORD文件';
        break;
      case 'glb':
        fileType = 'vr';
        textType = 'VR文件';
        break;
      case'mp3':
      case 'wav':
      case 'aac':
      case 'flac':
        fileType = 'audio'; // 常见音频后缀示例，可补充更多
        textType = '音频文件';
        break;
      case 'task':
        fileType = 'task';
        textType = '任务';
        break;
      case 'link':
        fileType = 'link';
        textType = '网页链接';
        break;
      default:
        fileType = 'unknown';
        textType = '未知类型';
    }
    console.log(textType)
    this.iconType = fileType
    this.textType = textType
  }

  getFormatSize (sizeInBytes) {
    if (sizeInBytes < 1024) {
      return sizeInBytes.toFixed(2) + " KB";
    } else if (sizeInBytes < 1024 * 1024) {
      let sizeInKB = sizeInBytes / 1024;
      return sizeInKB.toFixed(2) + " MB";
    }
    // else if (sizeInBytes < 1024 * 1024 * 1024) {
    else {

      let sizeInMB = sizeInBytes / (1024 * 1024);
      return sizeInMB.toFixed(2) + " GB";
    }
    // else {
    //   let sizeInGB = sizeInBytes / (1024 * 1024 * 1024);
    //   return sizeInGB.toFixed(2) + " GB";
    // }
  }

}

export default ArFile
