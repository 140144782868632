var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "course_wrapper cp v_resource_course_center_course_thumbnail",
      on: {
        click: function ($event) {
          return _vm.clickCoursePackage(_vm.item)
        },
      },
    },
    [
      _c("div", { staticClass: "img_group" }, [
        _c("div", {
          staticClass: "img",
          style: {
            backgroundImage: `url(${_vm.$file_url}${_vm.coursePackage.webpic})`,
          },
        }),
        _c("div", {
          staticClass: "img img_url",
          style: {
            backgroundImage: `url(${_vm.$img_url}${_vm.coursePackage.webpic})`,
          },
        }),
      ]),
      _c("div", { staticClass: "info_group" }, [
        _c("div", { staticClass: "name" }, [
          _vm._v("\n      " + _vm._s(_vm.coursePackage.title) + "\n    "),
        ]),
        _c("div", { staticClass: "info" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.coursePackage.ownedEnterprises) +
              "｜" +
              _vm._s(_vm.coursePackage.userName) +
              "\n    "
          ),
        ]),
      ]),
      _c("div", { staticClass: "fun_group d-flex align-center justify-end" }, [
        _c("div", { staticClass: "icon user" }),
        _c("div", { staticClass: "num" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.coursePackage.studyCount || 0) + "\n    "
          ),
        ]),
        _c("div", { staticClass: "icon zan" }),
        _c("div", { staticClass: "num" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.coursePackage.likeCount || 0) + "\n    "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }