<template>
  <div
    class="c_collect_main bbox d-flex flex-column"
  >
    <navigation-bar
      class="flex-shrink-0"
      :preview="preview"
      :top_jump="top_jump"
    ></navigation-bar>
    <div class="main_wrapper d-flex flex-column">
      <template
        v-if="(folder_list && folder_list.length) || (file_list && file_list.length)"
      >
        <folder-component
          v-if="folder_list && folder_list.length"
          :list="folder_list"
          :preview="preview"
          :current_folder="current_folder"
          :switch_select="switch_select_folder"
          :disable_select="switch_select_file"
          :show_menu="show_menu"
          :call="call"
          ref="folder_list"
          @handleStartSelect="handleStartSelectFolder"
        ></folder-component>
        <file-component
          v-if="file_list && file_list.length"
          :list="file_list"
          :preview="preview"
          :current_folder="current_folder"
          :nomore="nomore_file"
          :show_menu="show_menu"
          :call="call"
          :switch_select="switch_select_file"
          :disable_select="switch_select_folder"
          @handleStartSelect="handleStartSelectFile"
          @handleLoadMore="handleLoadMoreFile"
          :select-list="selectList"
          @selectTask="selectTask"
          :only-teach="onlyTeach"
        ></file-component>
      </template>
      <CourserPackageComponent
        :list="coursePackageList"
        :no-more="noMoreCourse"
      ></CourserPackageComponent>
      <ResourceFileComponent
        :list="resourceFileList"
        :no-more="noMoreResourceFile"
      ></ResourceFileComponent>

      <!--<div-->
      <!--  class="none_group bbox d-flex alig-center justify-center"-->
      <!--  v-else-->
      <!--&gt;-->
      <!--  <div class="none">-->
      <!--    <img src="~@/assets/images/common/none.png" alt="" class="img mb-20">-->
      <!--    <div class="text">暂无任务</div>-->
      <!--  </div>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import store from '@/store'
import global from '@/api/global.js'
import NavigationBar from '@/components/Collect/Navigation/index.vue'
import FolderComponent from '@/components/Collect/Folder/index.vue'
import FileComponent from '@/components/Collect/File/index.vue'
import FolderTask from '@/models/folder/folder_task'
import ResourceFileComponent from '@/components/Collect/resource_file/index'
import CourserPackageComponent from '@/components/Collect/course_package/index'
import {listForClasses, PFolder, PTask} from '@/types/index.js'
import CoursePackage from '@/models/cource_package/index'
import ResourceFile from '@/models/resource/resource_file'
import { listConvertToModel } from '@/models'
export default {
  components: {
    NavigationBar,
    FolderComponent,
    FileComponent,
    ResourceFileComponent,
    CourserPackageComponent,
  },
  computed: {
    collect_nav() {
      return store.state.collect_nav
    }
  },
  data(){
    return {
      folder_list: undefined,
      file_list: undefined,
      search: '',
      nomore_file: false,
      loading_folder: false,
      loading_file: false,
      current_folder: undefined,
      coursePackageList: undefined,
      noMoreCourse: false,
      resourceFileList: undefined,
      noMoreResourceFile: false,
      initLoading: false,
    }
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    p_folder: {
      type: Object,
      default: undefined,
    },
    switch_select_folder: {
      type: Boolean,
      default: undefined,
    },
    switch_select_file: {
      type: Boolean,
      default: undefined,
    },
    call: { // 是否调用
      type: Boolean,
      default: false,
    },
    show_menu: {
      type: Boolean,
      default: true,
    },
    top_jump: { // 顶级是否跳转
      type: Boolean,
      default: true,
    },
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 只显示教学任务
    onlyTeach : {
      type: Boolean,
      default: false,
    },

  },
  watch:{
    // 根据导航条变化重新加载数据
    collect_nav: {
      handler: function(val){
        this.search = ''
        this.init()
      },
      deep: true,
    },
    switch_select_folder: function(val){
      if(val === false){
        const {folder_list} = this
        if(folder_list && folder_list.length){
          folder_list.forEach(item=>{
            item.cancelSelect()
          })
        }
      }
    },
    switch_select_file: function(val){
      if(val === false){
        const {file_list} = this
        if(file_list && file_list.length){
          file_list.forEach(item=>{
            item.cancelSelect()
          })
        }
      }
    }
  },
  mounted(){
    const {preview, p_folder} = this
    // 预览初始不加载 在职collect_nav更新时加载
    if(preview){
      return
    }
    // 初次加载时 判断cookie里是否有存储导航
    const cookie_collect_nav = this.$tools.getCookie('collect_nav')
    if(cookie_collect_nav){
      store.commit('set_collect_nav', listForClasses({
        list: cookie_collect_nav,
        classes: PFolder,
      }))
    }
    // cookie 没有导航 但是路由有文件夹id
    if(store.state.collect_nav === undefined){
      const {$route} = this
      const {params, name} = $route
      const {folderid} = params
      if(name === 'fun_template_my_custom' && folderid){
        store.commit('set_collect_nav', new PFolder({id:folderid}))
        return // 等待watch执行init
      }
    }
    this.init()
  },
  methods: {
    changeSearch(search){
      this.search = search
      this.init()
    },
    clearFolder(){
      this.$set(this, 'folder_list', undefined)
      this.coursePackageList = undefined
      this.resourceFileList = undefined
    },
    clearFile(){
      this.$set(this, 'file_list', undefined)
    },
    async init(){
      if (this.initLoading) {
        return
      }
      this.clearFolder()
      this.getFolder()
      this.clearFile()
      this.initLoading = true
      await this.getFile()
      await this.getResourceFileList()
      await this.getCoursePackageList()
      this.initLoading = false
      console.log(this.selectList)
    },
    async getFolder(){
      const {collect_nav, search:keyword} = this
      const data = {
        sort: 0,
        keyword,
      }
      if((collect_nav??'') === ''){
        data.fId = 0
      }else{
        if(!collect_nav.length){
          return
        }
        data.fId = collect_nav[collect_nav.length - 1].shareid || collect_nav[collect_nav.length - 1].id
      }
      if(this.loading_folder){
        return
      }
      this.loading_folder = true
      const res = await this.$global.doPost({
        url: '/projectCustom/queryFolder',
        data,
      })
      this.loading_folder = false
      if(res.message === 'success'){
        if(res.contents && res.contents.pFolderList){
          this.$set(this, 'folder_list', [
            ...new Set([
              ...this.folder_list??[],
              ...listForClasses({
                list:res.contents.pFolderList,
                classes: PFolder,
              })
            ])
          ])
        }
        if(res.contents && res.contents.pFolder){
          this.$set(this, 'current_folder', new PFolder(res.contents.pFolder))
        }else{
          this.$set(this, 'current_folder', new PFolder({id:0, name:'我收藏的'}))
        }
      }
    },
    async handleLoadMoreFile(){
      await this.getFile()
    },
    async getFile(){

      const {collect_nav, search:keyword} = this
      const data = {
        sort: 0,
        keyword,
      }
      if(this.file_list && this.file_list.length){
        data.start = this.file_list.length
      }
      if((collect_nav??'') === ''){
        data.fId = 0
      }else{
        if(!collect_nav.length){
          return
        }
        data.fId = collect_nav[collect_nav.length - 1].shareid || collect_nav[collect_nav.length - 1].id
      }
      if(this.loading_file){
        return
      }
      this.loading_file = true
      const res = await this.$global.doPost({
        url: '/projectCustom/queryFolderTask',
        data,
      })
      this.loading_file = false
      if(res.message === 'success'){
        if(res.contents && res.contents.pFolderTasksList){
          this.$set(this, 'file_list', [
            ...new Set([
              ...this.file_list??[],
              ...listForClasses({
                list:res.contents.pFolderTasksList,
                classes: FolderTask,
              })
            ])
          ])
        }
        if(!res.contents || !res.contents.pFolderTasksList || res.contents.pFolderTasksList.length < 20){
          this.nomore_file = true
        }else{
          this.nomore_file = false
        }
      }
    },
    getListData () {
      const {collect_nav, search:keyword} = this
      const data = {
        sort: 0,
        keyword,
      }
      if(this.file_list && this.file_list.length){
        data.start = this.file_list.length
      }
      if((collect_nav??'') === ''){
        data.fId = 0
      }else{
        if(!collect_nav.length){
          return undefined
        }
        data.fId = collect_nav[collect_nav.length - 1].shareid || collect_nav[collect_nav.length - 1].id
      }
      return data
    },
    async getCoursePackageList () {
      const autoData = this.getListData()
      if (autoData === undefined) {
        return
      }
      const data = Object.assign(
        {},
        autoData,
        {
          type: 2,
          start: this.coursePackageList ? this.coursePackageList.length : 0,
        }
      )
      const res = await this.$global.doPost({
        url: '/projectCustom/queryFolderTask',
        data,
      })

      if(res.message === 'success'){
        if(res.contents && res.contents.pFolderTasksList){
          // this.$set(this, 'file_list', [
          //   ...new Set([
          //     ...this.file_list??[],
          //     ...listForClasses({
          //       list:res.contents.pFolderTasksList,
          //       classes: FolderTask,
          //     })
          //   ])
          // ])
          const list = res.contents.pFolderTasksList.map((item) => {
            return item.cource
          })
          //  将数据添加到coursePackageList中
          this.$set(this, 'coursePackageList', [
            ...new Set([
              ...this.coursePackageList??[],
              ...listConvertToModel({
                list,
                model: CoursePackage,
              })
            ])
          ])
          console.log(this.coursePackageList)
        }
        if (!res.contents || !res.contents.pFolderTasksList || res.contents.pFolderTasksList.length < 20) {
          this.noMoreCourse = true
        } else {
          this.noMoreCourse = false
        }
      }
      console.log(data)
    },
    async getResourceFileList () {
      const autoData = this.getListData()
      if (autoData === undefined) {
        return
      }
      const data = Object.assign(
        {},
        autoData,
        {
          type: 3,
          start: this.resourceFileList? this.resourceFileList.length : 0,
        }
      )
      const res = await this.$global.doPost({
        url: '/projectCustom/queryFolderTask',
        data,
      })
      if(res.message ==='success'){
        if(res.contents && res.contents.pFolderTasksList){
          // this.$set(this, 'file_list', [
          //  ...new Set([
          //    ...this.file_list??[],
          //    ...listForClasses({
          //       list:res.contents.pFolderTasksList,
          //       classes: FolderTask,
          //     })
          //   ])
          // ])
          const list = res.contents.pFolderTasksList.map((item) => {
            return item.teachfile
          })
          this.$set(
            this,
            'resourceFileList',
            [
              ...new Set([
                ...this.resourceFileList ?? [],
                ...listConvertToModel({
                  list,
                  model: ResourceFile
                })
              ])
            ]
          )
          console.log(this.resourceFileList)
        }
        if(!res.contents ||!res.contents.pFolderTasksList || res.contents.pFolderTasksList.length < 20){
          this.noMoreResourceFile = true
        }else{
          this.noMoreResourceFile = false
        }
      }
    },
    handleCreateFolder(p_folder){
      this.$set(this, 'folder_list', [
        ...new Set([
          ...[p_folder],
          ...this.folder_list
        ])
      ])
      // this.folder_list.unshift(p_folder)
      this.$refs.folder_list.goTop()
    },
    handleStartSelectFolder(){
      this.$emit('handleStartSelectFolder')
    },
    handleStartSelectFile(){
      this.$emit('handleStartSelectFile')
    },
    handleRemove(aim){
      // console.log('handleRemove', aim)
      const {switch_select_folder, switch_select_file, folder_list, file_list} = this
      const data = {
        toid: aim.id
      }
      let ids = ''
      if(switch_select_folder){
        data.type = 0
        if(folder_list && folder_list.length){
          folder_list.forEach(item=>{
            if(item.cf && item.cf.selected){
              ids += `${ids?',':''}${item.id}`
            }
          })
        }
      }
      if(switch_select_file){
        data.type = 1
        if(file_list && file_list.length){
          file_list.forEach(item=>{
            if(item.cf && item.cf.selected){
              ids += `${ids?',':''}${item.id}`
            }
          })
        }
      }
      if(ids === ''){
        this.$notice({desc: '没有选择任务或分组'})
        return
      }
      data.ids = ids

      this.$openConfirm({
        title:`是否移动到 '${aim.name}' ?`,
        desc:'',
        sure:'确定',
        cancel:'取消',
        onSure:async ()=>{

          let res = await global.doPost({
            url: '/projectCustom/moveFolderOrTask',
            data,
            notice: '移动失败',
          })
          if(res.message=='success'){

            if(switch_select_folder){
              const new_list = folder_list.filter(item=>{
                return !item.cf || (item.cf && !item.cf.selected)
              })
              this.$set(this, 'folder_list', listForClasses({
                list: new_list,
                classes: PFolder,
              }))
            }
            if(switch_select_file){
              const new_list = file_list.filter(item=>{
                return !item.cf || (item.cf && !item.cf.selected)
              })
              this.$set(this, 'file_list', listForClasses({
                list: new_list,
                classes: PTask,
              }))
            }
            this.$emit('handleCancelSelect')

          }
        },
        onCancel:()=>{},
      })
    },
    handleRemoveSelectedFile(){
      const {file_list} = this
      const new_list = []
      if(file_list && file_list.length){
        file_list.forEach(item=>{
          if(!item.cf || !item.cf.selected || !item.collectionflag){
            new_list.push(item)
          }
        })
        this.$set(this, 'file_list', new_list)
      }
    },
    handleRemoveSelectedFolder(){
      const {folder_list} = this
      const new_list = []
      if(folder_list && folder_list.length){
        folder_list.forEach(item=>{
          if(!item.cf || !item.cf.selected || !item.collectionflag){
            new_list.push(item)
          }
        })
        this.$set(this, 'folder_list', new_list)
      }
    },
    selectTask (taskInfo) {
      this.$emit('selectTask', taskInfo)
    },
  }
}
</script>


<style lang="scss" scoped>
.c_collect_main{
  width: 100%;
  .main_wrapper{
    width: 100%;
    flex:1;
    overflow: auto;
    .none_group{
      width: 100%;
      height: 100%;
      text-align: center;
      .text{
        color:#333;
        font-size: 14px;
      }
    }
  }
}
</style>
