var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_collect_file_index bbox d-flex flex-column" },
    [
      _c("div", { staticClass: "title_group flex-shrink-0 bbox" }, [
        _vm._v("\n    课程\n  "),
      ]),
      _c("div", { staticClass: "list_wrapper bbox" }, [
        _c(
          "div",
          {
            staticClass:
              "list_group bbox d-flex flex-wrap align-start justify-start",
          },
          [
            _vm._l(_vm.list, function (item, index) {
              return [
                _c("CoursePackageComponent", {
                  key: index,
                  attrs: {
                    "course-package": item,
                    "show-check-and-time": false,
                  },
                }),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "loading_group un_sel d-flex justify-center" },
          [
            !_vm.noMore
              ? _c(
                  "div",
                  {
                    staticClass: "loading cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.getMore()
                      },
                    },
                  },
                  [_vm._v("\n        加载更多\n      ")]
                )
              : _c("div", { staticClass: "nomore" }, [
                  _vm._v("\n        没有更多了\n      "),
                ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }