var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout_dialog" }, [
    _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "input_title_box" }, [
        _vm.dialog_type === "group" && _vm.$store.state.dialog_data
          ? _c("div", { staticClass: "basic_input_group" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$store.state.chat_detail &&
                      _vm.$store.state.chat_detail.chat &&
                      _vm.$store.state.chat_detail.chat.chatName
                  ) +
                  " (" +
                  _vm._s(_vm.$store.state.dialog_data.users) +
                  ")\n      "
              ),
            ])
          : _vm._e(),
        _vm.dialog_type === "single"
          ? _c("div", { staticClass: "basic_input_group" }, [
              _vm._v("\n        " + _vm._s(_vm.single_name) + "\n      "),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.$store.state.chat_detail &&
                  _vm.$store.state.chat_detail.chat &&
                  _vm.$store.state.chat_detail.chat.courceId,
                expression:
                  "$store.state.chat_detail && $store.state.chat_detail.chat && $store.state.chat_detail.chat.courceId",
              },
            ],
            staticClass: "course_tag",
          },
          [_vm._v("\n        教学群\n      ")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dialog_type === "group" && 0,
                expression: "dialog_type === 'group' && 0",
              },
            ],
            staticClass: "edit cp",
            on: { click: _vm.basicInputFocus },
          },
          [_vm._v("点击修改")]
        ),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.$store.state.chat_detail &&
                _vm.$store.state.chat_detail.isdisturb,
              expression:
                "$store.state.chat_detail && $store.state.chat_detail.isdisturb",
            },
          ],
          staticClass: "do_not_distrub flex-shrink-0",
        }),
      ]),
      _c("div", { staticClass: "fun_group" }, [
        _c("div", {
          directives: [
            { name: "show", rawName: "v-show", value: 0, expression: "0" },
          ],
          staticClass: "lock",
        }),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSystemUser,
              expression: "!isSystemUser",
            },
          ],
          staticClass: "fun cp",
          on: { click: _vm.switchDrawer },
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "content_group" },
      [
        _vm.$tools.getChatType() === 1
          ? _c(
              "div",
              {
                staticClass: "announcement_switch_group cp",
                class: {
                  on: _vm.announcement_switch,
                  new_msg:
                    _vm.message_list_data &&
                    _vm.message_list_data.chat &&
                    _vm.message_list_data.chat.readflag,
                },
                on: { click: _vm.openAnnouncement },
              },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.message_list_data &&
                        _vm.message_list_data.chat &&
                        _vm.message_list_data.chat.readflag,
                      expression:
                        "message_list_data && message_list_data.chat && message_list_data.chat.readflag",
                    },
                  ],
                  staticClass: "read_flag",
                }),
                _c("div", { staticClass: "text" }, [_vm._v("展示公示板")]),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.announcement_switch,
                      expression: "!announcement_switch",
                    },
                  ],
                  staticClass: "switch",
                  attrs: {
                    src: require("@/assets/images/dialog/announcement_open.png"),
                    alt: "",
                  },
                }),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "announcement_group",
            class: { on: _vm.announcement_switch },
          },
          [
            _vm.announcement_switch
              ? _c("public-notice", {
                  ref: "public_notice",
                  on: { handleClose: _vm.closeAnnouncement },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "dialog_group" }, [
          _c(
            "div",
            { ref: "dialog_box", staticClass: "dialog_box" },
            [
              _c("router-view", {
                ref: "dialog_scroll",
                staticClass: "router_view",
              }),
              _vm.dialogData.unreadNumber !== 0
                ? _c(
                    "div",
                    {
                      staticClass: "unread_message_group cp",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.checkUnreadNumber()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dialogData.unreadNumber) +
                          "条新消息\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "sidebar" } },
                [
                  _vm.switch_sidebar
                    ? _c("side-bar", { staticClass: "side_bar_wrapper" })
                    : _vm._e(),
                ],
                1
              ),
              _c("transition", { attrs: { name: "sidebar" } }, [
                1
                  ? _c("div", { staticClass: "msg_group" }, [
                      _c(
                        "div",
                        {
                          staticClass: "notice_group cp no_notice",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.openSidebar()
                            },
                          },
                        },
                        [
                          _vm.chat_detail &&
                          _vm.chat_detail.chat &&
                          _vm.chat_detail.chat.sidebarflag
                            ? [
                                _c("img", {
                                  staticClass: "notice",
                                  attrs: {
                                    src: require("@/assets/images/dialog/notice.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "msg_number" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.sidebarNumber(
                                        _vm.chat_detail.chat.sidebarflag
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSystemUser,
                expression: "!isSystemUser",
              },
            ],
            staticClass: "fun_group",
          },
          [_c("operating-space", { attrs: { dialog: _vm.dialog } })],
          1
        ),
        _c(
          "el-drawer",
          {
            attrs: {
              "custom-class": "dialog_drawer_el_wrapper",
              visible: _vm.drawer,
              "with-header": false,
              modal: false,
              "modal-append-to-body": false,
              wrapperClosable: false,
              size: "300px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.drawer = $event
              },
              open: function ($event) {
                return _vm.openDrawer()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "drawer_wrapper",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$api.stopPropagation($event)
                  },
                },
              },
              [
                _c("DialogDrawer", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dialog_type === "group",
                      expression: "dialog_type === 'group'",
                    },
                  ],
                  ref: "drawerGroup",
                  on: { closeDrawer: _vm.closeDrawer },
                }),
                _c("DialogDrawerSingle", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dialog_type === "single",
                      expression: "dialog_type === 'single'",
                    },
                  ],
                  ref: "drawerSingle",
                  on: { closeDrawer: _vm.closeDrawer },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }