<template>
  <div
    id="fun_task_dialog"
    class="fun_task_dialog"
    ref="scrollBox"
    @dragenter="handleDragEnter"
    @dragover="handleDragOver"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <div class="loading_group" v-show="isGettingMsg && !nomore">
      <div class="icon"></div>
      <div class="text">加载中</div>
    </div>
    <div class="nomore_group" v-if="nomore">
      <div class="nomore">没有新的消息了</div>
    </div>
    <!-- <template v-for="(item,index) in msgList" > -->
    <template v-for="(item,index) in message_list" >
      <div
        class="dialog_unit"
        ref="dialog_unit"
        :key="index+'22'"
        v-if="item.logType !== 1 && item.logType !== 5 && !is_system && !item.pseudo_delete"
      >
        <msg-unit
          :type="Number(item.fromUserId) === Number(userId) ? 'send' : 'receive'"
          :msg_data="item"
          :prev="index !== 0 ? message_list[index-1] : undefined"
        ></msg-unit>
      </div>
      <!-- <div class="dialog_unit" ref="dialog_unit" :key="index+'11'">
        <MsgUnit type="send"></MsgUnit>
      </div> -->
      <div
        v-if="item.logType === 1 || item.logType === 5"
        :key="index"
        ref="dialog_unit"
      >
        <welcome-unit
          :item="item"
        ></welcome-unit>
      </div>
    </template>
  </div>
</template>
<script>
import MsgUnit from '@/components/dialog/msgUnit.vue';
import WelcomeUnit from '@/components/dialog/share/unit.vue';
import {listForClasses, PTaskLog} from '@/types'
import store from '@/store'
import im from '@/utils/WebIM/im'
export default {
  components:{
    MsgUnit,
    WelcomeUnit,
  },
  data(){
    return {
      msgList: null,
      // chatId: this.$route.params.id,
      // chatId: this.$api.moduleTask.getRouteParamsId(),
      userId: this.$tools.getUserId(),
      // scrollto 配置
      options: {
        container: '#fun_task_dialog', //容器
        easing: 'ease-in',
        offset: 0,
        force: true,
        cancelable: true,
        // onStart: function(element) {
        //   // scrolling started
        // },
        // onDone: function(element) {
        //   // scrolling is done
        // },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true
      },
      scrollTop: 1000,
      isGettingMsg: false,
      nomore: false
    }
  },
  watch:{
    '$route.params.id': async function(newVal){
      if(newVal){
        // 关闭编辑任务浮层
        this.$store.commit('set_edit_task_box', false)

        // this.chatId =  this.$api.moduleTask.getRouteParamsId();
        await this.init()
        // this.scrollToBottom();
        this.$nextTick(function(){
          this.$refs.scrollBox.removeEventListener('scroll', this.scrollEventListenerHandler)
          this.scrollToBottom()
          //  200ms后开启监听
          setTimeout(() => {
            this.$refs.scrollBox.addEventListener('scroll', this.scrollEventListenerHandler, false)
          }, 200)

        })
      }
    },
    message_list:{
      handler: function(val){
        // // console.log(val);
        if(!val || val.length === 0 || val.length < 20){
          this.nomore = true;
        }
        // this.scrollToBottom();
      },
      deep: true
    },
    /**
     * 加载更多
     */
    scrollTop: async function(val){
      if(val < 200){
        if(!this.isGettingMsg){
          // // console.log('获取新消息')
          this.isGettingMsg = true
          await this.unshiftMessageList()
        }
      }
      let {scrollHeight, clientHeight} = this.$refs.scrollBox
      // // console.log(this.$store.state.message_box_unit);
      let message_box_unit = this.$store.state.message_box_unit
      let message_box_list = this.$tools.deepClone(this.$store.state.message_box_list)
      if((scrollHeight - (val + clientHeight)) < 20 && message_box_unit && message_box_unit.unreadMessageNum){
        await this.$http.request('/projectTask/unreadTozero',{chat_id: message_box_unit.chatId, chat_type: message_box_unit.chatType})
        // 置空未读数
        let index = message_box_list.findIndex( item => {
          return item.chatId === message_box_unit.chatId && item.chatType === message_box_unit.chatType
        })
        if (index !== -1) {
          message_box_list[index].unreadMessageNum = 0
        }
        this.$store.commit('set_message_box_list', message_box_list)
      }
    },
    // 触发滚动到底部
    task_trigger_scroll_to_bottom: function(val){
      // console.log('task_trigger_scroll_to_bottom',val);
      if(val){
        this.$nextTick(function(){
          this.scrollToBottom();
          this.$store.commit('set_task_trigger_scroll_to_bottom', false)
        })
      }
    }
  },
  computed: {
    chatId () {
      const id = this.$route.params.id
      if (id) {
        return this.$global.decodeBase64(id)
      }
      return ''
    },
    message_list () {
      return this.$store.state.message_list
    },
    task_trigger_scroll_to_bottom () {
      return this.$store.state.task_module.task_trigger_scroll_to_bottom
    },
    /**
     * 是否是系统推送[小妙]
     */
    is_system () {
      const { chatId } = this
      return chatId === this.$cm_id
    }
  },
  methods: {
    handleDragEnter (e) {
      // console.log('拖拽进入窗口')
      // console.log(event)
      e.preventDefault()
      e.stopPropagation()
    },
    handleDragOver (e) {
      e.preventDefault()
      e.stopPropagation()
    },
    handleDragLeave (e) {
      e.preventDefault()
      e.stopPropagation()
    },
    handleDrop (e) {
      e.preventDefault()
      e.stopPropagation()
      console.log('拖拽进入窗口')
      console.log(e)
      console.log(e.dataTransfer, e.dataTransfer.files, e.dataTransfer.items)
    },
    /**
     * 查看未读消息
     */
    checkUnreadNumber () {
      // console.log('checkUnreadNumber')
      const { unreadNumber } = store.state.dialogData
      if(this.$refs.dialog_unit && this.$refs.dialog_unit.length !== 0){
        let element = this.$refs.dialog_unit[this.$refs.dialog_unit.length - unreadNumber]
        this.$scrollTo(element,1, this.options)
        store.state.dialogData.unreadNumber = 0
      }
    },
    /**
     * 获取消息列表projectTask/messageList
     */
    async getMsgList () {
      store.commit(
        'set_message_list',
        []
      )
      await this.getChatDetail()
      await store.dispatch('get_message_list')
      im.sendMySelf()
      this.scrollToBottom()
      store.commit(
        'set_task_trigger_scroll_to_bottom',
        false
      )
    },
    async getChatDetail () {
      const chatId = this.$api.moduleTask.getRouteParamsId()
      const chatType = this.$tools.getChatType()
      if (!chatId) {
        return
      }
      const res = await this.$api.dialog.getChatDetail({
        chatId,
        chatType
      })
      if (res) {
        this.$store.commit('set_chat_detail', res)
      }
    },
    /**
     * 最近模板列表
     */
    async getMyTaskList () {
      this.$store.commit('set_my_task_list', [])
      this.$store.dispatch('get_my_task_list')
    },
    async init () {
      // console.log('init', this)
      this.getMsgList()
      // this.getMyTaskList();
      this.scrollTop = this.$refs.scrollBox.scrollHeight
      this.isGettingMsg = false
      this.nomore = false
      this.$refs.scrollBox.removeEventListener('scroll', this.scrollEventListenerHandler)
      this.$nextTick(function () {
        this.scrollToBottom()
        this.$store.commit('set_task_trigger_scroll_to_bottom', false)
        //  200ms后开启监听
        setTimeout(() => {
          if (this.$refs.scrollBox) {
            this.$refs.scrollBox.addEventListener('scroll', this.scrollEventListenerHandler, false)
          }
        }, 200)
      })
    },
    /**
     * @description: 滚动到底部
     * @return:
     */
    scrollToBottom () {
      // 获取最后一个元素
      if (this.$refs.dialog_unit && this.$refs.dialog_unit.length !== 0) {
        let element = this.$refs.dialog_unit[this.$refs.dialog_unit.length - 1]
        this.$scrollTo(element,1, this.options)
      }
    },
    /**
     * 滚动监听函数
     */
    scrollEventListenerHandler () {
      this.scrollTop = this.$refs.scrollBox.scrollTop
      const scrollHeight = this.$refs.scrollBox.scrollHeight
      const calcHeight = this.$refs.scrollBox.scrollTop + this.$refs.scrollBox.clientHeight
      // console.log(Math.abs(scrollHeight - calcHeight))
      if (Math.abs(scrollHeight - calcHeight) <= 1) {
        store.state.dialogData.isBottom = true
        store.state.dialogData.unreadNumber = 0
      } else {
        store.state.dialogData.isBottom = false
      }
      // console.log(store.state.dialogData.isBottom)
    },
    /**追加消息列表 */
    async unshiftMessageList(){
      try {
        let message_list = this.$tools.deepClone(this.message_list);
        let chatType = 1;
        if(this.$route.name === 'fun_task_dialog_single'){
          chatType = 0;
        }
        if (message_list&& message_list.length) {
          let res = await this.$http.request('/projectTask/messageList', {chatType,chatId: this.chatId,startId: message_list ? message_list[0].id : ''});
          let tasklogList = [];
          if(res.contents && res.contents.tasklogList){
            tasklogList = res.contents.tasklogList.reverse();
          }
          if(tasklogList && tasklogList.length){
            let new_message_list = [...tasklogList, ...message_list];
            new_message_list = listForClasses({
              list: new_message_list,
              classes: PTaskLog,
            })
            this.$store.commit('set_message_list', new_message_list);
          }
          if(tasklogList.length === 0){
            this.nomore = true;
            return ;
          }
          //跳转到追加前的位置 (-1 向上移动一位)
          this.$nextTick(function(){

            let element = this.$refs.dialog_unit[tasklogList.length - 2];
            this.$scrollTo(element,1, this.options);
          })

        }
        setTimeout(() => {
          this.isGettingMsg = false;
        }, 400)
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted() {
    // // console.log(this.task_trigger_scroll_to_bottom, 'this.task_trigger_scroll_to_bottom')
    this.init();
    this.nomore = !!(this.message_list && this.message_list.length && this.message_list < 20);
    this.$nextTick(function(){
      if (this.$refs.scrollBox) {
        this.$refs.scrollBox.removeEventListener('scroll', this.scrollEventListenerHandler)
      }
      this.scrollToBottom();
      this.$store.commit('set_task_trigger_scroll_to_bottom', false);
      //  200ms后开启监听
      setTimeout(() => {
        this.$refs.scrollBox.addEventListener('scroll', this.scrollEventListenerHandler, false);
      }, 200);

    })

  },
}
</script>

<style lang="scss" scoped>
.fun_task_dialog{
  @include hidescrollbar;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  .dialog_unit{
    width:100%;
    padding-bottom: 22px;
    &:last-child{
      padding-bottom: 0;
    }
  }
  .loading_group{
    @include flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0;
    .icon{
      animation: myfirst 1s linear infinite;
      -webkit-animation: myfirst 1s linear infinite;
      transform-origin: center;
      transform: rotate(0deg);
      width: 16px;
      height: 16px;
      background: {
        size: 100%;
        image: url(~@/assets/images/common/loadmore.png);
      }
    }
    .text{
      padding-left: 10px;
      color: #999;
      font-size: 14px;
    }
  }
  .nomore{
    @include flex;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    color: #999;
    padding: 20px 0 40px;
  }
}
@keyframes myfirst
{
    0%   {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
}

/* Safari 与 Chrome */
@-webkit-keyframes myfirst
{
    0%   {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
}

// @keyframes myfirst
// {
//     0%   {background: red;}
//     25%  {background: yellow;}
//     50%  {background: blue;}
//     100% {background: green;}
// }

// /* Safari 与 Chrome */
// @-webkit-keyframes myfirst
// {
//     0%   {background: red;}
//     25%  {background: yellow;}
//     50%  {background: blue;}
//     100% {background: green;}
// }

</style>
