<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),
    src() {
      const {
        resourceFileData
      } = this
      const {
        file,
      } = resourceFileData
      const {
        url
      } = file
      return `${ this.$file_url }${ url }`
      // return `${ this.$file_url }pmcs/test/2024/12/16/1734316747880wzscnb.mp3`
    }
  }
}
</script>

<template>
  <div
    class="v_resource_center_detail_type_audio d-flex justify-center"
  >
    <div
      class="microsoft_file d-flex justify-center"
    >
      <!--{{ src }}-->
      <iframe
        :src="src"
        width="100%"
        height="200px"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<style scoped lang="scss">
.v_resource_center_detail_type_audio {
  width: 100%;
  .microsoft_file {
    width: 100%;
    max-width: 1000px;
  }
}
</style>
