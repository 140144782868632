<script>
import ResourceTitleGroup from '@/views/fun_template/resource/title_group'
import ResourcePageType from '@/views/fun_template/resource/page_type'
import ResourceFilter from '@/views/fun_template/resource/filter_group'
import CourseCenter from '@/views/fun_template/resource/course_center/index'
import ResourceCenterPage from '@/views/fun_template/resource/resource_center/index'
import ResourceCenterFilterGroup from '@/views/fun_template/resource/resource_center/filter_group'
import store from '@/store'
import ResourceSearch from '@/views/fun_template/resource/search/index'
import { mapState } from 'vuex'
export default {
  components: {
    ResourceTitleGroup,
    ResourcePageType,
    ResourceFilter,
    CourseCenter,
    ResourceCenterPage,
    ResourceCenterFilterGroup,
    ResourceSearch,
  },
  data () {
    return {
      pageType: 0,
      pageLoading: false,
      searchMode: false,
      page: undefined,
    }
  },
  provide () {
    return {
      page: this.providePage,
    }
  },
  computed: {
    ...mapState({
      coursePackage: state => state.course_package.data,
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
      pageStatus: state => state.pageStatus.resource,
    }),
    providePage () {
      return this.page
    },
    showCourseCenter () {
      const {
        pageType
      } = this.pageStatus;
      return pageType === 0;
    },
    showResourceCenter () {
      const {
        pageType
      } = this.pageStatus;
      return pageType === 1;
    },
    showShoppingMail () {
      const {
        $route
      } = this
      if ($route.name.includes('shopping_mail')) {
        return true
      }
      return false
    }
  },
  mounted () {
    store.commit(
      'setCoursePackageData',
      undefined
    )
    store.commit(
      'setCoursePackageTask',
      undefined
    )
  },
  methods: {
    changePageType (type) {
      // if (this.pageType === type) {
      //   return
      // }
      // this.pageType = type
      // if (type === 3) {
      //   this.$router.push({
      //     name: 'fun_template_shopping_mail'
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'fun_template_resource'
      //   })
      // }
    },
    changePageLoading (loading) {
      this.pageLoading = loading
    },
    changeSearchMode (data) {
      this.searchMode = data
    },
    changePage (page) {
      // console.log(page)
      this.page = page
      // this.$set(
      //   this,
      //   'page',
      //   page
      // )
      // console.log(this.page)
    },
    async currentPageChange (page) {
      if (this.pageLoading) {
        return
      }
      this.pageLoading = true
      // console.log(page)
      const {
        pageType,
      } = this.pageStatus
      if (pageType === 0) {
        const res = await store.dispatch(
          'getCoursePackageList',
          {
            pn: page
          }
        )
        this.pageLoading = false
        if (res.message === 'success') {
          this.page = res.contents.page
          // this.$set(
          //   this,
          //   'page',
          //   res.contents.page
          // )
        }
      }
      if (pageType === 1) {
        const res = await store.dispatch(
          'getResourceFileList',
          {
            pn: page
          }
        )
        this.pageLoading = false
        if (res.message === 'success') {
          this.page = res.contents.page
          // this.$set(
          //   this,
          //   'page',
          //   res.contents.page
          // )
        }
      }
    }
  },
  destroyed () {
    store.commit(
      'setCoursePackageData',
      undefined
    )
    store.commit(
      'setCoursePackageTask',
      undefined
    )
    store.commit(
      'setResourceFileData',
      undefined
    )
  }
}
</script>

<template>
<div
  v-loading="pageLoading"
  class="v_fun_template_resource_index d-flex flex-column bbox_wrapper"
>
  <template
    v-if="!searchMode"
  >
    <ResourceTitleGroup
      class="flex-shrink-0"
      @changePageLoading="changePageLoading"
      @changeSearchMode="changeSearchMode"
    ></ResourceTitleGroup>
    <ResourcePageType
      class="flex-shrink-0"
      :page-type="pageType"
      @changePageType="changePageType"
    ></ResourcePageType>
    <ResourceFilter
      v-if="pageStatus.pageType === 0 && !coursePackage"
      class="flex-shrink-0"
      @changePageLoading="changePageLoading"
      @changePage="changePage"
    ></ResourceFilter>
    <ResourceCenterFilterGroup
      v-if="pageStatus.pageType === 1 && !resourceFileData"
      @changePageLoading="changePageLoading"
      @changePage="changePage"
    ></ResourceCenterFilterGroup>
    <div
      class="main_wrapper d-flex flex-column"
    >
      <CourseCenter
        v-if="showCourseCenter"
        @changePage="changePage"
        :page="page"
      ></CourseCenter>
      <ResourceCenterPage
        v-if="showResourceCenter"
        @changePage="changePage"
        :page="page"
      ></ResourceCenterPage>
      <RouterView
        v-if="showShoppingMail"
      ></RouterView>
    </div>
    <div
      class="page_wrapper flex-shrink-0 d-flex justify-center"
    >
      <el-pagination
        v-if="Boolean(page && !resourceFileData)"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-count="page.totalPages"
        :current-page="page.pageNo"
        @current-change="currentPageChange"
      >
      </el-pagination>
    </div>
  </template>
  <template
    v-if="searchMode"
  >
    <ResourceSearch
      @changeSearchMode="changeSearchMode"
    ></ResourceSearch>
  </template>

</div>
</template>

<style scoped lang="scss">
.v_fun_template_resource_index {
  .main_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .page_wrapper {
    background-color: #fff;
    width: 100%;
    padding: 5px 0;
  }
}
</style>
