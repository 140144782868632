var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass:
        "v_fun_template_resource_search_index bbox_wrapper d-flex align-start flex-column",
    },
    [
      _c(
        "div",
        { staticClass: "title_group d-flex align-center flex-shrink-0" },
        [
          _c("div", {
            staticClass: "icon",
            on: { click: _vm.changeSearchMode },
          }),
          _c("div", { staticClass: "title" }, [_vm._v("\n      搜索\n    ")]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.currentTaskDetail,
              expression: "!currentTaskDetail",
            },
          ],
          staticClass: "search_page_wrapper d-flex align-start flex-column",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showDetail,
                  expression: "!showDetail",
                },
              ],
              staticClass: "search_wrapper d-flex align-center flex-shrink-0",
            },
            [
              _c("div", { staticClass: "search_group d-flex align-center" }, [
                _c("div", { staticClass: "icon flex-shrink-0" }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.keyword,
                      expression: "keyword",
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", placeholder: "请输入" },
                  domProps: { value: _vm.keyword },
                  on: {
                    keydown: _vm.inputKeyDown,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.keyword = $event.target.value
                    },
                  },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.keyword !== "",
                      expression: "keyword !== ''",
                    },
                  ],
                  staticClass: "clear cp",
                  on: { click: _vm.touchClear },
                }),
              ]),
              _c(
                "div",
                { staticClass: "btn cp un_sel", on: { click: _vm.doSearch } },
                [_vm._v("\n        搜索\n      ")]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDetail,
                  expression: "showDetail",
                },
              ],
              staticClass: "navigation_group d-flex align-center flex-shrink-0",
            },
            [
              _c(
                "div",
                { staticClass: "tag cp", on: { click: _vm.backToSearch } },
                [_vm._v("\n        搜索\n      ")]
              ),
              _c("div", { staticClass: "text" }, [
                _vm._v("\n        >\n      "),
              ]),
              _c("div", { staticClass: "tag on" }, [
                _vm._v("\n        " + _vm._s(_vm.detailName) + "\n      "),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showDetail,
                  expression: "!showDetail",
                },
              ],
              staticClass: "filter_wrapper flex-shrink-0",
            },
            [
              _c("div", { staticClass: "filter_group d-flex align-center" }, [
                _c("div", { staticClass: "key" }, [
                  _vm._v("\n          分 类:\n        "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "value",
                    class: {
                      on: _vm.searchType === 1,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeSearchType(1)
                      },
                    },
                  },
                  [_vm._v("\n          课程\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "value",
                    class: {
                      on: _vm.searchType === 2,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeSearchType(2)
                      },
                    },
                  },
                  [_vm._v("\n          素材\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "value",
                    class: {
                      on: _vm.searchType === 3,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeSearchType(3)
                      },
                    },
                  },
                  [_vm._v("\n          模板商城\n        ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showDetail,
                  expression: "!showDetail",
                },
              ],
              staticClass: "content_wrapper",
            },
            [
              _vm.searchType === 1
                ? _c("div", { staticClass: "course_package_list_wrapper" }, [
                    _vm.coursePackageList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "course_package_list d-flex flex-wrap align-start",
                          },
                          [
                            _vm._l(
                              _vm.coursePackageList,
                              function (item, index) {
                                return [
                                  _c("CoursePackageThumbnail", {
                                    key: index,
                                    staticClass: "course_wrapper cp",
                                    attrs: { "course-package": item },
                                    on: {
                                      clickCoursePackage:
                                        _vm.clickCoursePackage,
                                    },
                                  }),
                                  0
                                    ? _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "course_wrapper cp",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickCoursePackage(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "img_group" },
                                            [
                                              _c("div", {
                                                staticClass: "img",
                                                style: {
                                                  backgroundImage: `url(${_vm.$file_url}${item.webpic})`,
                                                },
                                              }),
                                              _c("div", {
                                                staticClass: "img img_url",
                                                style: {
                                                  backgroundImage: `url(${_vm.$img_url}${item.webpic})`,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "info_group" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "name" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.title) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "info" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        item.ownedEnterprises
                                                      ) +
                                                      "｜" +
                                                      _vm._s(item.userName) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fun_group d-flex align-center justify-end",
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "icon user",
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "num" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.studyCount) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticClass: "icon zan",
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "num" },
                                                [_vm._v("3392")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.searchType === 2
                ? _c("div", { staticClass: "file_list_wrapper" }, [
                    _vm.resourceFileList
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "file_list d-flex flex-wrap align-start",
                          },
                          [
                            _vm._l(
                              _vm.resourceFileList,
                              function (item, index) {
                                return [
                                  0
                                    ? _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "resource_group d-flex align-center",
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewFile(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "icon_group flex-shrink-0",
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "icon",
                                                class: {
                                                  [item.fileType]: true,
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "info_group" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "name" },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.fileName) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "detail_group d-flex align-center justify-space-between",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "detail" },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            item.userName
                                                          ) +
                                                          " | " +
                                                          _vm._s(
                                                            item.ownedEnterprises
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._m(0, true),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("FileThumbnail", {
                                    key: index,
                                    attrs: { "file-data": item },
                                    on: { viewFile: _vm.viewFile },
                                  }),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.searchType === 3 && !_vm.showDetail
                ? _c("div", { staticClass: "shopping_wrapper" }, [
                    _c("div", { staticClass: "shopping_group" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "list_wrapper" }, [
                        _c("div", { staticClass: "list" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "package_wrapper d-flex align-start flex-wrap",
                            },
                            [
                              _vm._l(
                                _vm.shoppingPackageList,
                                function (item, index) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "package_group",
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewSearchSellPackage(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "img_group" },
                                          [
                                            _c("div", {
                                              staticClass: "img",
                                              style: {
                                                backgroundImage: `url(${_vm.$img_url}${item.webpic})`,
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", { staticClass: "name" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.title) +
                                              "\n                    "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          _vm.morePackage
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "more_group d-flex align-center justify-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "more d-flex cp align-center cp un_sel justify-center",
                                      on: { click: _vm.loadMorePackage },
                                    },
                                    [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                    查看更多\n                  "
                                        ),
                                      ]),
                                      _c("div", { staticClass: "icon" }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._m(2),
                      _c("div", { staticClass: "list_wrapper" }, [
                        _c("div", { staticClass: "list" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "task_wrapper d-flex align-start flex-wrap",
                            },
                            [
                              _vm._l(
                                _vm.shoppingTaskList,
                                function (item, index) {
                                  return [
                                    _c("TaskModule", {
                                      key: index,
                                      staticClass: "task_group",
                                      attrs: { ptask: item },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.checkTaskDetail(item)
                                        },
                                      },
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _vm.moreTask
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "more_group d-flex align-center justify-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "more d-flex cp align-center cp un_sel justify-center",
                                    on: { click: _vm.loadMoreTask },
                                  },
                                  [
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(
                                        "\n                  查看更多\n                "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "icon" }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDetail,
                  expression: "showDetail",
                },
              ],
              staticClass: "content_wrapper",
            },
            [
              _vm.coursePackage
                ? _c("CheckCrousePackage", {
                    attrs: {
                      "show-top": false,
                      "view-mode": true,
                      "audit-mode": false,
                    },
                  })
                : _vm._e(),
              _vm.resourceFileData ? _c("ResourceCenterDetail") : _vm._e(),
              _vm.sellPackage
                ? _c(
                    "div",
                    { staticClass: "task_detail_wrapper" },
                    [
                      _c("TeachingPackageDetailPage", {
                        attrs: {
                          package_id: _vm.sellPackage.id,
                          "pop-task": true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.searchType !== 3 && !_vm.showDetail
            ? _c(
                "div",
                {
                  staticClass:
                    "page_wrapper flex-shrink-0 d-flex justify-center",
                },
                [
                  _vm.page
                    ? _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "prev, pager, next",
                          total: _vm.page.totalPages,
                          "current-page": _vm.page.pageNo,
                        },
                        on: { "current-change": _vm.changeCurrentPage },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentTaskDetail,
              expression: "currentTaskDetail",
            },
          ],
          staticClass: "detail_wrapper d-flex align-start flex-column",
        },
        [
          _vm.currentTaskDetail
            ? _c(
                "div",
                {
                  staticClass:
                    "return_wrapper d-felx align-center flex-shrink-0",
                },
                [
                  _c(
                    "div",
                    { staticClass: "text cp", on: { click: _vm.goToSearch } },
                    [_vm._v("\n        搜索\n      ")]
                  ),
                  _c("div", { staticClass: "symbol" }, [
                    _vm._v("\n        >\n      "),
                  ]),
                  _c("div", { staticClass: "text on" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.currentTaskDetail.name) +
                        "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.currentTaskDetail
            ? _c(
                "div",
                { staticClass: "task_detail_wrapper" },
                [
                  _c("TaskDetail", {
                    attrs: { task_id: _vm.currentTaskDetail.id },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "number_group d-flex align-center" }, [
      _c("div", { staticClass: "icon" }),
      _c("div", { staticClass: "number" }, [_vm._v("2.3w")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "type_group" }, [
      _c("div", { staticClass: "type" }, [
        _vm._v("\n              付费课程\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "type_group" }, [
      _c("div", { staticClass: "type" }, [
        _vm._v("\n              任务模板\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }