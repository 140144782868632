var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_resource_center_detail_type_microsoft_file d-flex justify-center",
    },
    [
      _c(
        "div",
        { staticClass: "microsoft_file d-flex justify-center flex-column" },
        [
          _c("div", { staticClass: "error_wrapper d-flex justify-center" }, [
            _c("div", { staticClass: "text" }, [_vm._v("如页面无法加载, 请")]),
            _c(
              "div",
              { staticClass: "link cp px-2", on: { click: _vm.goToUrl } },
              [_vm._v("\n        点击此处\n      ")]
            ),
            _c("div", { staticClass: "text" }, [
              _vm._v("\n        在新页面中查看\n      "),
            ]),
          ]),
          _c("iframe", {
            ref: "iframe",
            attrs: {
              src: _vm.src,
              width: "100%",
              height: "600px",
              frameborder: "0",
              onerror: "iframeError",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }