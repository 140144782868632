var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_resource_center_detail_type_audio d-flex justify-center",
    },
    [
      _c("div", { staticClass: "microsoft_file d-flex justify-center" }, [
        _c("iframe", {
          attrs: {
            src: _vm.src,
            width: "100%",
            height: "800px",
            frameborder: "0",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }