<template>
  <div
    class="c_collect_file_index bbox d-flex flex-column "
  >
    <div class="title_group flex-shrink-0 bbox">任务</div>
    <div class="list_wrapper bbox">
      <div class="list_group bbox d-flex flex-wrap align-start justify-start">
        <template
          v-for="(item,index) in list"
        >
          <file-unit
            v-if="!onlyTeach || (onlyTeach && item.task.taskType === 2)"
            :key="index"
            class="mr-10 mb-5"
            :p_task="item.task"
            :switch_select="switch_select"
            :preview="preview"
            :show_menu="show_menu"
            :call="call"
            :current_folder="current_folder"
            :disable_select="disable_select"
            :select-list="selectList"
            @handleRemoveCollect="handleRemoveCollect"
            @handleStartSelect="handleStartSelect"
            @handleRemove="handleRemove"
            @click.native.stop="selectTask(item.task)"
          ></file-unit>
        </template>
      </div>
      <div class="loading_group un_sel d-flex justify-center">
        <div class="loading cp" v-if="!nomore"
          @click.stop="getMore()"
        >加载更多</div>
        <div class="nomore" v-else>没有更多了</div>
      </div>
    </div>

  </div>
</template>

<script>
import FileUnit from './FileUnit.vue'
export default {
  components: {
    FileUnit,
  },
  props: {
    list: {
      type: Array,
      default: undefined,
    },
    switch_select: {
      type: Boolean,
      default: false,
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    nomore: {
      type: Boolean,
      default: false,
    },
    current_folder: {
      type: Object,
      default: undefined,
    },
    call: { // 是否调用
      type: Boolean,
      default: false,
    },
    show_menu: {
      type: Boolean,
      default: true,
    },
    selectList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 只显示教学任务
    onlyTeach : {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    /**
     * 移除(取消收藏)
     */
    handleRemoveCollect(p_task){
      const {list} = this
      this.$openConfirm({
        title:'是否取消收藏',
        desc:'',
        sure:'',
        sureType: '', // 确定按钮样式 '','warn'
        cancel:'',
        onSure: async ()=>{
          // console.log(p_task.removeCollect)
          const res= await p_task.removeCollect({ confirm: false })
          if(res){
            this.$notice({
              desc: '移除成功',
              type: 'success'
            })
            const index = list.findIndex(item=>{
              return item.id === p_task.id
            })
            if(index !== -1){
              list.splice(index, 1)
            }
          }
        },
        onCancel:()=>{},
      })
    },
    handleStartSelect(){
      this.$emit('handleStartSelect')
    },
    handleRemove(p_task){
      console.log(p_task)
      const {list} = this
      const index = list.findIndex(item=>{
        return item.id === p_task.id
      })
      if(index !== -1){
        list.splice(index, 1)
      }
    },
    getMore(){
      this.$emit('handleLoadMore')
    },
    selectTask (taskInfo) {
      this.$emit('selectTask', taskInfo)
    },
  },
  mounted () {
    console.log('index.vue', this.selectList)
  }
}
</script>

<style lang="scss" scoped>
.c_collect_file_index{
  width: 100%;
  overflow: auto;
  .title_group{
    width: 100%;
    font-size: 16px;
    color: $main_blue;
    line-height: 24px;
    border-left: 4px solid $main_blue;
    padding: {
      left: 20px;
    }
    margin: 10px 0;
    &.call_custom{
      margin-left: 20px;
    }
  }
  .list_wrapper{
    @include scrollbar;
    width: 100%;
    padding: 10px 20px;
    overflow: auto;
    flex: 1;
  }
  .list_group{
    width: 100%;
    flex: 1;
  }
  .loading_group{
    font-size: 14px;
    color: #999;
    padding: 10px 0;
    text-align: center;
    .loading{
      color: $main_blue;
    }
  }
}
</style>
