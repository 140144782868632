var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "views_shopping_mail_task_details" }, [
    _vm.ptask
      ? _c(
          "div",
          {
            staticClass:
              "summary_group bbox d-flex align-center justify-space-between",
          },
          [
            _c("div", { staticClass: "info_group bbox" }, [
              _c("div", { staticClass: "title_group d-flex align-center" }, [
                _c("div", { staticClass: "title flex-shrink-0 mr-10" }, [
                  _vm._v(_vm._s(_vm.ptask.name)),
                ]),
                _vm.ptask.price !== 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "consult_group cp un_sel d-flex align-center mr-15",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.doConsulting()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon mr-3",
                          attrs: {
                            src: require("@/assets/images/shopping_mail/consult_icon.png"),
                          },
                        }),
                        _c("div", { staticClass: "consult" }, [
                          _vm._v("购买咨询"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "collect_group cp un_sel d-flex align-center mr-15",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchCollect()
                      },
                    },
                  },
                  [
                    !_vm.ptask.collectionflag
                      ? _c("img", {
                          staticClass: "icon mr-3",
                          attrs: {
                            src: require("@/assets/images/shopping_mail/collect_icon.png"),
                          },
                        })
                      : _vm._e(),
                    _vm.ptask.collectionflag
                      ? _c("img", {
                          staticClass: "icon mr-3",
                          attrs: {
                            src: require("@/assets/images/shopping_mail/collect_icon_active.png"),
                          },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "collect" }, [
                      _vm._v(
                        _vm._s(_vm.ptask.collectionflag ? "已收藏" : "收藏")
                      ),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "sub_title flex-shrink-0 pt-10 d-flex align-start",
                },
                [
                  _c("div", { staticClass: "key flex-shrink-0 pr-5" }, [
                    _vm._v("简介："),
                  ]),
                  _c("div", {
                    staticClass: "content",
                    domProps: { innerHTML: _vm._s(_vm.ptask.introduction) },
                  }),
                ]
              ),
              0
                ? _c("div", { staticClass: "details pt-6" }, [
                    _vm._v(
                      "PMCS-E102\n        《会展策划与管理》配套实践任务模块\n        《会展策划与管理》是学习会展经济与管理、会展策划与管理、会展服务与管理相关专业的必修课程，所有从事会展及相关工作的岗位均需要了解整个知识体系结构以及策划与管理原理。\n        任务总数：65\n        适用教材：\n        《会展策划与管理》王春雷-高等教育出版社-2018年12月\n        《会展业导论》王春雷（译）-重庆大学出版社-2018年11月\n        《会展项目管理》刘大可-中国人民大学出版社-2017年4月\n        《会展项目管理-理论、方法与实践》江金波-清华大学出版社-2014年2月\n      "
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "data_group pt-10 d-flex align-center" },
                [
                  0
                    ? _c("div", { staticClass: "category mr-20" }, [
                        _vm._v("会展专业 / 会展营销 / 市场营销"),
                      ])
                    : _vm._e(),
                  _c("img", {
                    staticClass: "icon mr-4",
                    attrs: {
                      src: require("@/assets/images/shopping_mail/time_icon.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text pr-20" }, [
                    _vm._v(
                      _vm._s(
                        _vm
                          .$moment(_vm.ptask.createTime)
                          .format("YYYY年MM月DD日")
                      )
                    ),
                  ]),
                  0
                    ? [
                        _c("img", {
                          staticClass: "icon mr-4",
                          attrs: {
                            src: require("@/assets/images/shopping_mail/sale_icon.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "text pr-20" }, [
                          _vm._v("9876"),
                        ]),
                        _c("img", {
                          staticClass: "icon mr-4",
                          attrs: {
                            src: require("@/assets/images/shopping_mail/view_icon.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "text" }, [_vm._v("9.2w")]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "lecturer_group d-flex align-center mt-10" },
                [
                  _c("div", { staticClass: "text mr-10" }, [_vm._v("讲师:")]),
                  _c("img", {
                    staticClass: "avatar mr-8",
                    attrs: {
                      src: `${_vm.$avatar_url}${_vm.ptask.createuserthumbnail}@!small200`,
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.ptask.createusername)),
                  ]),
                ]
              ),
            ]),
            _vm.showPay && _vm.canShowBuy
              ? _c(
                  "div",
                  { staticClass: "payment_group flex-shrink-0 ml-20" },
                  [
                    _c(
                      "div",
                      { staticClass: "button_group d-flex align-center mt-20" },
                      [
                        !_vm.ptask.buy
                          ? _c(
                              "div",
                              {
                                staticClass: "button buy btn_dark pay",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.buyNow({
                                      successText: "领取成功",
                                    })
                                  },
                                },
                              },
                              [_vm._v("\n            领取任务\n          ")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.payment !== false && !_vm.showPay
              ? _c(
                  "div",
                  { staticClass: "payment_group flex-shrink-0 ml-20" },
                  [
                    !_vm.ptask.showflag
                      ? _c("div", { staticClass: "sold_out pr-20" }, [
                          _vm._v("未上架"),
                        ])
                      : [
                          _c("div", { staticClass: "price" }, [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("售价："),
                            ]),
                            _c("span", { staticClass: "number" }, [
                              _vm._v("¥" + _vm._s(_vm.ptask.price / 100)),
                            ]),
                            _vm.ptask.buy
                              ? _c("span", { staticClass: "buy_hint" }, [
                                  _vm._v("[已购买]"),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "button_group d-flex align-center mt-20",
                            },
                            [
                              0
                                ? _c(
                                    "div",
                                    { staticClass: "button add mr-10" },
                                    [_vm._v("加入定制清单")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "button buy",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.buyNow()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ptask.price === 0
                                        ? "免费领取"
                                        : "立即购买"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "task_group mt-20" },
      [
        _c("div", { staticClass: "mask" }),
        _vm.loading_init
          ? _c("task", {
              staticClass: "task_details_group",
              attrs: {
                max_width: "none",
                max_height: "none",
                box_shadow: "box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06)",
                show_intro: false,
                shopping_mail: true,
                border: "border: 1px solid #E8EEFF",
                sell: true,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { ref: "task_list", staticClass: "section" },
      [
        _c("title-module", {
          staticClass: "section_title_group mb-20",
          attrs: { title: "讲师其他课程", show_more: false },
        }),
        _c(
          "div",
          {
            staticClass:
              "list_group task_list_group d-flex align-start flex-wrap",
          },
          [
            _vm._l(_vm.lecturer_task, function (item, index) {
              return [
                index < _vm.task_line_number && item.id !== _vm.ptask.id
                  ? _c("task-module", {
                      key: index,
                      staticClass: "mr-16 task_module mb-16",
                      attrs: { ptask: item },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.viewTask(item)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ],
      1
    ),
    _c("div", { staticClass: "nomore" }, [_vm._v("————  已经到底啦  ————")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }