<script>
import { mapState } from 'vuex'
import store from '@/store'
import request from '@/api/request'
import CoursePackage from '@/models/cource_package'
import SectionList from '@/components/resource_library/course_package/check/section_list'
import vue from '@/main'
import global from '@/api/global'
import tools from '@/api/tools'

export default {
  components: {
    SectionList,
  },
  computed: {
    ...mapState({
      // courseId: state => state.course_package.id,
      coursePackage: state => state.course_package.data,
      message_list: state => state.message_list,
      // coursePackageList: state => state.course_package.packageList,
    }),
    courseType () {
      const {
        coursePackage
      } = this
      const {
        type
      } = coursePackage
      if (type === 1) {
        return '标准课'
      }
      if (type === 2) {
        return '实践课'
      }
      if (type === 3) {
        return '专业群课'
      }
      if (type === 4) {
        return '平台使用教程'
      }
      if (type === 5) {
        return '培训课'
      }
      if (type === 6) {
        return '其他'
      }
      return ''
    },
    statusType () {
      const {
        checkstatus
      } = this
      if (checkstatus === 1) {
        return ''
      }
      if (checkstatus === 2) {
        return 'audit'
      }
      if (checkstatus === 3) {
        return 'approve'
      }
      if (checkstatus === 4) {
        return 'fail'
      }
      return ''
    },
    statusText () {
      const {
        checkstatus
      } = this
      if (checkstatus === 1) {
        return '未审核'
      }
      if (checkstatus === 2) {
        return '待审核'
      }
      if (checkstatus === 3) {
        return '审核通过'
      }
      if (checkstatus === 4) {
        return '审核不通过'
      }
      return ''
    },
    showApplyBtn () {
      const loginUserId = tools.getUserId()
      const {
        coursePackage,
      } = this
      if (Number(coursePackage.createuser.userId) === Number(loginUserId)) {
        return false
      }
      const {
        showApply,
        showStartStudy,
      } = this
      return showApply && !showStartStudy
    },
    // showStartStudy 当用户不是作者, 并且已经领取了课程, 并且不是审核模式时, 显示开始学习
    showStartStudy () {
      const loginUserId = tools.getUserId()
      if (!loginUserId) {
        return false
      }
      const {
        coursePackage,
        auditMode,
        showApply,
      } = this
      if (!coursePackage) {
        return false
      }
      const {
        createuser,
        getFlag,
      } = coursePackage
      if (!createuser) {
        return false
      }

      if (Number(loginUserId) === Number(createuser.userId)) {
        return false
      }
      if (auditMode) {
        return false
      }
      if (showApply && getFlag === 1) {
        return true
      }
      return false
    }
  },
  props: {
    showTop: { type: Boolean, default: true, }, // 是否显示顶部
    auditMode: { type: Boolean, default: true, }, // 审核模式
    viewMode: { type: Boolean, default: false, }, // 查看顶部
    alertTask: { type: Boolean, default: true }, // 点击弹出任务
    showEdit: { type: Boolean, default: false }, // 显示编辑按钮
    showApply: { type: Boolean, default: true }, // 显示报名学习
  },
  provide() {
    return {
      auditMode: this.auditMode,
    }
  },
  data () {
    return {
      loading: false,
      typeList: [
        {
          name: '课程目录',
          type: 1,
        },
        {
          name: '课程简介',
          type: 2,
        },
        {
          name: '课程概述',
          type: 3,
        },
        {
          name: '课程标准',
          type: 4,
        },
      ],
      type: 1,
      switchSelect: false,
      checkstatus: 1,
      initLoading: false,
    }
  },
  mounted () {
    // console.log('init')
    this.init()
  },
  methods: {
    changeType (type) {
      if (this.type === type) {
        return
      }
      const { coursePackage } = this
      const { docfile, videofile } = coursePackage
      if (type === 4) {
        if (docfile) {
          const basic_url = 'https://view.officeapps.live.com/op/view.aspx?src='
          window.open(`${ basic_url }${ this.$file_url }${ docfile }`, '_blank')
        } else {
          this.$notice ({
            desc: '作者未上传课程标准',
            type: 'error'
          })
        }
        return
      }
      if (type === 3) {
        if (videofile) {
          let protocol = location.protocol
          let src = vue.$file_url
          // 根据当前环境切换http/https源
          if (protocol === 'http:') {
            src = vue.$file_url_http;
          }
          src += videofile
          vue.$openVideo({ src })
        } else {
          this.$notice ({
            desc: '作者未上传课程概述',
            type: 'error'
          })
        }
        return
      }
      this.type = type
    },
    async init () {
      // 清空选择的章节
      store.commit(
        'setCoursePackageSection',
        undefined
      )
      // const { id } = store.state.course_package
      if (this.coursePackage && this.coursePackage.id) {
        // 获取数据
        await this.getCoursePackage()
        this.checkstatus = this.coursePackage.checkstatus
      }
      this.initLoading = true
    },
    async getCoursePackage () {
      const {
        coursePackage
      } = this
      this.loading = true
      const res = await coursePackage.getData()
      this.loading = false
      return res
    },
    doClose () {
      // console.log('doClose')
      store.commit(
        'setCoursePackageData',
        undefined
      )
      if (this.auditMode) {
        this.$modal.hide('globalCheckCrousePackage')
      }
      if (this.viewMode) {
        this.$modal.hide('globalViewCrousePackage')
      }

    },
    doSelect (checkstatus) {
      this.switchSelect = false
      if (this.checkstatus === checkstatus) {
        return
      }
      this.checkstatus = checkstatus
      if (this.checkstatus === 4) {
        // 弹出原因
      }
    },
    async doApprove () {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await this.coursePackage.approve()
      this.loading = false
      if (res) {
        const {
          message_list
        } = this
        if (message_list && message_list.length) {
          message_list.forEach((unit) => {
            if (
              unit.logType === 7
              &&
              unit.taskId === this.coursePackage.id
            ) {
              unit.checkstatus = 3
            }

          })
        }
        this.$notice({
          desc: '提交成功',
          type: 'success'
        })
        this.doClose()
      }
    },
    async doSubmit () {
      if (this.checkstatus === 4) {
        store.commit(
          'setTaskInfo',
          undefined
        ) // 清除任务信息
        this.$modal.show('globalCoursePackageFailReason')
        return
      }
      if (this.checkstatus === 3) {
        this.doApprove()
        return
      }

    },
    /**
     * 建群上课
     * @return {Promise<void>}
     */
    async createClassRoom () {
      console.log('建群上课')
      console.log(store.state.user)
      if (!store.state.user.sign) {
        this.$notice({
          desc: "无法使用，请联系管理员开通讲师权限",
          type: 'error'
        })
        return
      }
      this.$api.common_set_add_contact_to_dialog_box()
      store.commit(
        'set_add_contact_dialog_box_type',
        'create'
      )
    },
    async doLike () {
      const {
        coursePackage
      } = this
      const {
        likeFlag,
      } = coursePackage
      const res = await this.coursePackage.doLike()
      if (res.message === 'success') {
        this.$notice ({
          desc: likeFlag ? '取消成功' : '点赞成功',
          type: 'success'
        })
      }
    },
    /**
     * 执行分享操作
     *
     * 此函数调用API发起分享操作，特别适用于课程包的分享场景
     * 它通过将当前课程包的信息与操作类型一起传递给后端，以启动分享流程
     *
     * @apiNote
     * - API调用: 调用`$api.share.doStartShare`方法
     * - 参数:
     *   - data: 传递课程包数据，使用Object.assign创建新对象以避免直接修改原始数据
     *   - type: 设置分享类型为5，此处的5可能代表特定的分享场景或模式
     * - 异步操作: 使用async声明，意味着此函数内部可以使用await来等待异步操作完成
     */
    async doShare () {
      this.$api.share.doStartShare({
        data: Object.assign(
          {},
          this.coursePackage,
        ), type: 5}
      );
    },
    /**
     * 收藏
     * @return {Promise<void>}
     */
    async touchCollect () {
      const {
        coursePackage,
      } = this
      this.$collectSelectRoute({
        onSure: async (params)=>{
          const res = await global.doPost({
            url: '/projectCustom/customFolderCource',
            data: {
              courceId: coursePackage.id,
              folderId: params.p_folder.id,
            }
          })
          if(res.message === 'success'){
            coursePackage.customFlag = 1
            coursePackage.collectCount++
            this.$notice({desc:"收藏成功", type: 'success'})
          }
        }
      })
    },
    /**
     * 报名学习
     * @return {Promise<void>}
     */
    async touchStudy () {
      const {
        coursePackage,
      } = this
      const {
        getFlag,
      } = coursePackage
      if (getFlag) {
        this.$notice({
          desc: '已领取过该课程',
          type: 'error'
        })
        return
      }
      const res = await this.coursePackage.doGet()

      if (res.message === 'success') {
        this.$notice({
          desc: '报名成功',
          type: 'success'
        })
      } else {
        this.$notice({
          desc: res.describe,
          type: 'error'
        })
      }
    },
    touchEdit () {
      this.$modal.show('globalCoursePackageEdit')
    },
    // touchStartStudy 点击开始学习 跳转到我领取的课程详情页
    touchStartStudy () {
      // 如果显示开始学习的标志位为真，则调用触摸开始学习的方法
      this.$modal.hide(
        'globalViewCrousePackage'
      )
      this.$router.push({
        name: 'fun_template_my',
        params: {
          type: 'purchase'
        }
      })
      this.$nextTick(function () {
        store.commit(
          'setMyResourcePageStatus',
          {
            pageTag: 'course'
          }
        )
        store.commit(
          'setCoursePackageData',
          this.coursePackage
        )
      })

    },
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="c_rl_course_package_check d-flex flex-column bbox_wrapper"
>
  <div
    v-if="showTop"
    class="top_bar_wrapper flex-shrink-0 d-flex align-center justify-end"
  >
    <div
      v-show="coursePackage.checkstatus === 2"
      class="btn btn_dark cp"
      @click.stop="doSubmit"
    >
      提交
    </div>
    <div
      class="close cp"
      @click.stop="doClose"
    ></div>
  </div>
  <div
    v-if="initLoading"
    class="info_wrapper d-flex align-stretch justify-space-between flex-shrink-0"
  >
    <div
      class="info_group d-flex align-start "
    >
      <div
        class="pic_group flex-shrink-0"
      >
        <div
          v-if="!coursePackage.webpic"
          class="text_group d-flex align-center justify-center"
        >
          <div
            class="title"
          >
            {{ coursePackage.title }}
          </div>
        </div>
        <div
          v-if="coursePackage.webpic"
          class="pic"
          :style="{
            backgroundImage: `url(${ $file_url }${ coursePackage.webpic })`
          }"
        ></div>
        <div
          v-if="coursePackage.webpic"
          class="pic img_pic"
          :style="{
            backgroundImage: `url(${ $img_url }${ coursePackage.webpic })`
          }"
        ></div>
      </div>
      <div
        class="text_info_group d-flex flex-column align-start justify-space-between"
      >
        <div>
          <div
            class="title"
          >
            {{ coursePackage.title }}
          </div>
          <div
            class="number_info d-flex align-center"
          >
            <div
              class="text"
            >
              教师团队:
            </div>
            <div
              class="text blod"
            >
              {{ coursePackage.teachernum }}
            </div>
            <div
              class="text mr-5"
            >
              人
            </div>
            <div
              class="text"
            >
              任务数量:
            </div>
            <div
              class="text blod"
            >
              {{ coursePackage.tasknum }}
            </div>
            <div
              class="text mr-5"
            >
              个
            </div>
            <div
              class="text"
            >
              素材数量:
            </div>
            <div
              class="text blod"
            >
              {{ coursePackage.filenum }}
            </div>
            <div
              class="text mr-5"
            >
              个
            </div>
          </div>
        </div>
        <div>
          <div
            class="icon_info_group"
          >
            <div
              class="icon com"
            ></div>
            <div
              class="key"
            >
              单&nbsp;&nbsp;位:
            </div>
            <div
              class="value"
              v-if="coursePackage && coursePackage.createuser"
            >
              {{ coursePackage.createuser.ownedEnterprises }}
            </div>
          </div>
          <div
            class="icon_info_group"
          >
            <div
              class="icon type"
            ></div>
            <div
              class="key"
            >
              课程类型:
            </div>
            <div
              class="value"
            >
              {{ courseType }}
            </div>
          </div>
          <div
            class="icon_info_group"
          >
            <div
              class="icon time"
            ></div>
            <div
              class="key"
            >
              创建时间:
            </div>
            <div
              class="value"
            >
              {{ $moment(coursePackage.createTime).format('yyyy-MM-DD') }}
            </div>
          </div>
          <div
            v-if="!auditMode"
            class="btn_wrapper d-flex align-center flex-shrink-0"
          >
            <div
              class="btn_group cp d-flex align-center create"
              @click="createClassRoom"
            >
              <div
                class="icon create"
              ></div>
              <div
                class="text"
              >
                建群上课
              </div>
            </div>
            <div
              v-if="showApplyBtn"
              class="btn_group cp d-flex align-center apply"
              @click="touchStudy"
            >
              <div
                class="icon apply"
              ></div>
              <div
                class="text"
              >
                报名学习
              </div>
            </div>
            <div
              v-if="showStartStudy"
              class="btn_group cp d-flex align-center start_study"
              @click="touchStartStudy"
            >
              <div
                v-if="0"
                class="icon apply"
              ></div>
              <div
                class="text"
              >
                开始学习
              </div>
            </div>
            <div
              v-if="showEdit"
              class="btn_group cp d-flex align-center apply"
              @click="touchEdit"
            >
              <div
                class="icon edit"
              ></div>
              <div
                class="text"
              >
                编辑
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div
      v-if="auditMode"
      class="fun_group flex-shrink-0 align-center d-flex justify-center"
    >
      <div
        v-show="coursePackage.checkstatus === 2"
        class="select_group"
      >
        <el-popover
          v-model="switchSelect"
          placement="bottom"
          trigger="click"
        >
          <div
            class="c_rl_course_package_check_select_list_group"
          >
            <div
              class="select_item audit"
              @click.stop="doSelect(1)"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                待审核
              </div>
            </div>
            <div
              class="select_item approve"
              @click.stop="doSelect(3)"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                通过
              </div>
            </div>
            <div
              class="select_item fail"
              @click.stop="doSelect(4)"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                未通过
              </div>
            </div>
          </div>
          <template
            slot="reference"
          >
            <div
              class="select_btn_group d-flex align-center justify-center cp"
              :class="{
                [statusType]: true,
                on: switchSelect
              }"
            >
              <div
                class="icon"
              ></div>
              <div
                class="text"
              >
                {{ statusText }}
              </div>
              <div
                class="symbol"
              ></div>
            </div>
          </template>

        </el-popover>
      </div>
      <div
        v-show="coursePackage.checkstatus === 3 || coursePackage.checkstatus === 4"
        class="check_result_group"
      >
        <div
          class="check_group d-flex align-center"
          :class="{
            [statusType]: true
          }"
        >
          <div
            class="icon"
          ></div>
          <div
            class="text"
          >
            {{ statusText }}
          </div>
        </div>
        <div
          class="check_info_group d-flex flex-column"
        >
          <div
            v-if="coursePackage.checkstatus === 4"
            class="title"
          >
            未通过原因:
          </div>
          <div
            v-if="coursePackage.checkstatus === 4"
            class="check_reason"
          >
            {{ coursePackage.reason }}
          </div>
          <div
            class="title"
            :class="{
              right: coursePackage.checkstatus === 4
            }"
          >
            审核人: {{ coursePackage.checkUserName }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="viewMode"
      class="view_mode_wrapper d-flex flex-column align-end justify-space-between flex-shrink-0"
    >
      <div
        class="fun_group_wrapper d-flex align-center"
      >
        <div
          class="btn like"
          :class="{
            on: coursePackage.likeFlag
          }"
          @click="doLike"
        ></div>
        <div
          class="btn collect"
          :class="{
            on: coursePackage.customFlag
          }"
          @click="touchCollect"
        ></div>
        <div
          class="btn share"
          @click="doShare"
        ></div>
      </div>
      <div
        class="number_wrapper d-flex align-start"
      >
        <div
          class="number_group d-flex align-center flex-column"
        >
          <div
            class="number"
          >
            {{ coursePackage.studyCount || 0 }}
          </div>
          <div
            class="text flex-shrink-0"
          >
            学习
          </div>
        </div>
        <div
          class="number_group d-flex align-center flex-column"
        >
          <div
            class="number"
          >
            {{ coursePackage.chatCount || 0 }}
          </div>
          <div
            class="text flex-shrink-0"
          >
            建群量
          </div>
        </div>
        <div
          class="number_group d-flex align-center flex-column"
        >
          <div
            class="number"
          >
            {{ coursePackage.likeCount || 0 }}
          </div>
          <div
            class="text flex-shrink-0"
          >
            点赞量
          </div>
        </div>
        <div
          class="number_group d-flex align-center flex-column"
        >
          <div
            class="number"
          >
            {{ coursePackage.collectCount || 0 }}
          </div>
          <div
            class="text flex-shrink-0"
          >
            收藏量
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="initLoading"
    class="main_wrapper d-flex flex-column"
  >
    <div
      class="type_group d-flex align-center flex-shrink-0"
    >
      <template
        v-for="item in typeList"
      >
        <div
          :key="item.type"
          class="type"
          :class="{
            active: type === item.type
          }"
          @click.stop="changeType(item.type)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
    <div
      class="main_layout"
    >
      <div
        class="content_wrapper d-flex align-stretch "
      >
        <div
          class="content_group"
        >
          <template
            v-if="type === 1"
          >
            <SectionList
              :alert-task="alertTask"
            ></SectionList>
          </template>
          <template
            v-if="type === 2"
          >
            <div
              class="course_package_content_group"
            >
              <div
                class="content"
                v-html="coursePackage.content"
              ></div>
            </div>
          </template>
        </div>
        <div
          class="user_list_group d-flex flex-column flex-shrink-0"
        >
          <div
            class="title_group"
          >
            课程创建者
          </div>
          <div
            class="user_group d-flex align-center"
          >
            <div
              class="avatar_group"
            >
              <div
                class="avatar"
                :style="{
                  backgroundImage: `url(${ $avatar_url }${ coursePackage.createuser.thumbnail }@!small200)`
                }"
              ></div>
            </div>
            <div
              class="name_group"
            >
              <div
                class="name"
              >
                {{ coursePackage.createuser.userName }}
              </div>
              <div
                class="com"
                v-if="coursePackage && coursePackage.createuser"
              >
                {{ coursePackage.createuser.ownedEnterprises }}
              </div>
            </div>
          </div>
          <template
            v-if="coursePackage.taskteachers && coursePackage.taskteachers.length"
          >
            <div
              class="title_group"
            >
              组课教师
            </div>
            <template
              v-for="(item, index) in coursePackage.taskteachers"
            >
              <div
                :key="index"
                class="user_group d-flex align-center"
              >
                <div
                  class="avatar_group"
                >
                  <div
                    class="avatar"
                    :style="{
                  backgroundImage: `url(${ $avatar_url }${ item.thumbnail }@!small200)`
                }"
                  ></div>
                </div>
                <div
                  class="name_group"
                >
                  <div
                    class="name"
                  >
                    {{ item.userName }}
                  </div>
                  <div
                    class="com"
                  >
                    {{ item.ownedEnterprises }}
                  </div>
                </div>
              </div>
            </template>
          </template>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.c_rl_course_package_check {
  color: #333;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .top_bar_wrapper {
    width: 100%;
    padding: 10px 0;
    .btn {
      width: 68px;
      line-height: 30px;
      background: #0054A7;
      border-radius: 4px;
      font-size: 12px;
      color: #FFFFFF;
      text-align: center;
      font-style: normal;
    }
    .close {
      @include background(12px 12px);
      margin: 0 20px;
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/course_package/close.png);
    }
  }
  .info_wrapper {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 4px solid #f8f8f8;
    .info_group {
      width: 100%;
      padding: 0 20px;
      .pic_group {
        margin-right: 20px;
        width: 365px;
        height: 205px;
        position: relative;
        z-index: 2;
        .text_group {
          width: 100%;
          height: 100%;
          background-color: #ccc;
          padding: 0 20px;
          .title {
            @include single_line_intercept;
            font-size: 34px;
            font-weight: bold;
            color: #fff;
          }
        }
        .pic {
          @include background;
          width: 100%;
          height: 100%;
          background-size: contain;
          &.img_pic {
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }
      .text_info_group {
        height: 205px;
        .title {
          padding-bottom: 10px;
          font-weight: bold;
          font-size: 24px;
          color: #0054A7;
          line-height: 31px;
          text-align: left;
          font-style: normal;
        }
        .number_info {
          //margin-bottom: 40px;
          .text {
            font-size: 14px;
            line-height: 20px;
            color: #333333;
          }
          .blod {
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            font-weight: bold;
            margin: 0 3px;
          }
        }
        .icon_info_group {
          display: flex;
          align-self: center;
          padding-bottom: 10px;
          font-size: 14px;
          color: #333333;
          line-height: 19px;
          .icon {
            @include background(16px 15px);
            width: 20px;
            height: 20px;
            margin-right: 5px;
            flex-shrink: 0;
            &.com {
              background-image: url(~@/assets/images/course_package/check_com.png);
            }
            &.type {
              background-image: url(~@/assets/images/course_package/check_type.png);
            }
            &.time {
              background-image: url(~@/assets/images/course_package/check_time.png);
            }
          }
          .key {
            width: 70px;
            text-align: justify;
            text-align-last: justify;
            margin-right: 10px;
          }
        }
        .btn_wrapper {
          padding: 0;
          .btn_group {
            margin-top: 10px;
            margin-right: 10px;
            height: 30px;
            background: rgba(0,84,167,0.1);
            border-radius: 4px;
            color: $main_blue;
            &.create {
              background: rgba(0,84,167,1);
              padding: 0 56px;
              color: #fff;
            }
            &.apply {
              padding: 0 26px;
            }
            &.start_study {
              background-color: $main_blue;
              color: #fff;
              padding: 0 12px;
              .text {
                padding-left: 0;
              }
            }
            .icon {
              @include background;
              width: 14px;
              height: 14px;
              &.create {
                background-image: url(~@/assets/images/resource/course_package_create_icon.png);
              }
              &.apply {
                background-image: url(~@/assets/images/resource/course_package_apply_icon.png);
              }
              &.edit {
                background-image: url(~@/assets/images/resource/course_package_edit_icon.png);
              }
            }
            .text {
              padding-left: 5px;
              font-size: 12px;
              line-height: 16px;
              text-align: left;
              font-style: normal;
            }
          }
        }
      }
    }
    .fun_group {
      margin-left: 20px;
      padding: 20px 40px;
      .select_group {
        .select_btn_group {
          width: 240px;
          height: 44px;
          border-radius: 10px;
          border: 1px solid #ccc;
          position: relative;
          &.on {
            &.fail {
              .symbol {
                background-image: url(~@/assets/images/course_package/fail_close.png);
              }
            }
            &.audit {
              .symbol {
                background-image: url(~@/assets/images/course_package/audit_close.png);
              }
            }
            &.approve {
              .symbol {
                background-image: url(~@/assets/images/course_package/approve_close.png);
              }
            }

          }
          &.fail {
            border-color: #D82E0D;
            color: #D82E0D;
            .text {
              color: #D82E0D;
            }
            .icon {
              background-image: url(~@/assets/images/course_package/select_fail.png);
            }
            .symbol {
              background-image: url(~@/assets/images/course_package/fail_open.png);
            }
          }
          &.audit {
            border-color: #F78900;
            color: #F78900;
            .text {
              color: #F78900;
            }
            .icon {
              background-image: url(~@/assets/images/course_package/select_audit.png);
            }
            .symbol {
              background-image: url(~@/assets/images/course_package/audit_open.png);
            }
          }
          &.approve {
            border-color: #05AD00;
            color: #05AD00;
            .text {
              color: #05AD00;
            }
            .icon {
              background-image: url(~@/assets/images/course_package/select_approve.png);
            }
            .symbol {
              background-image: url(~@/assets/images/course_package/approve_open.png);
            }
          }
          .icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
          .text {
            font-size: 16px;
            color: #333;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
          }
          .symbol {
            @include background(11px 6px);
            position: absolute;
            z-index: 9;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;

          }
        }
      }
      .check_result_group {
        width: 240px;
        .check_group {
          .icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
          .text {
            font-size: 16px;
            color: #333;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
          }
          &.approve {
            border-color: #05AD00;
            color: #05AD00;
            .text {
              color: #05AD00;
            }
            .icon {
              background-image: url(~@/assets/images/course_package/select_approve.png);
            }
            .symbol {
              background-image: url(~@/assets/images/course_package/approve_open.png);
            }
          }
          &.fail {
            border-color: #D82E0D;
            color: #D82E0D;
            .text {
              color: #D82E0D;
            }
            .icon {
              background-image: url(~@/assets/images/course_package/select_fail.png);
            }
            .symbol {
              background-image: url(~@/assets/images/course_package/fail_open.png);
            }
          }
        }
        .check_info_group {
          margin-top: 15px;
          width: 240px;
          background: #F6F8FA;
          border-radius: 10px;
          padding: 20px;
          .title {
            width: 100%;
            height: 19px;
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            opacity: 0.5;
            &.right {
              text-align: right;
            }
          }
          .check_reason {
            width: 100%;
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            height: 90px;
            margin: 10px 0;
          }
        }
      }
    }
    .view_mode_wrapper {
      padding-right: 20px;
      .fun_group_wrapper {
        .btn {
          @include transition;
          width: 36px;
          height: 36px;
          margin-left: 24px;
          cursor: pointer;
          &.like {
            &.on {
              background-image: url(~@/assets/images/course_package/like_btn_on.png);
            }
            //&:hover {
            //  background-image: url(~@/assets/images/course_package/like_btn_on.png);
            //}
            background-image: url(~@/assets/images/course_package/like_btn.png);
          }
          &.collect {
            &.on {
              background-image: url(~@/assets/images/course_package/collect_btn_on.png);
            }
            //&:hover {
            //  background-image: url(~@/assets/images/course_package/collect_btn_on.png);
            //}
            background-image: url(~@/assets/images/course_package/collect_btn.png);
          }
          &.share {
            &.on {
              background-image: url(~@/assets/images/course_package/share_btn_on.png);
            }
            &:hover {
              background-image: url(~@/assets/images/course_package/share_btn_on.png);
            }
            background-image: url(~@/assets/images/course_package/share_btn.png);
          }
        }
      }
      .number_wrapper {
        .number_group {
          padding-left: 22px;
          position: relative;
          z-index: 2;
          margin-left: 22px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            height: 80%;
            width: 2px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: #f4f4f4;
          }
          &:nth-of-type(1) {
            &:before {
              display: none;
            }
          }
          .number {
            font-weight: bold;
            font-size: 20px;
            color: #0054A7;
            line-height: 26px;
            text-align: center;
            font-style: normal;
            padding-bottom: 6px;
          }
          .text {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            text-align: left;
            font-style: normal;
          }
        }
      }
    }
  }
  .main_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    .type_group {
      width: 100%;
      padding: 20px 40px;
      .type {
        cursor: pointer;
        position: relative;
        margin-right: 20px;
        padding-bottom: 14px;
        &.active {
          font-weight: bold;
          &::after{
            position: absolute;
            display: block;
            content: '';
            width: 20px;
            height: 2px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $main_blue;
          }
        }
      }
    }
    .main_layout {
      width: 100%;
      height: 100%;
      overflow: auto;
      .content_wrapper {
        width: 100%;
        height: 100%;
        overflow: auto;
        .user_list_group {
          width: 370px;
          height: 100%;
          border-left: 2px solid #f8f8f8;
          padding: 0 20px;
          overflow: auto;
          .title_group {
            font-weight: bold;
            font-size: 16px;
            color: #333333;
            line-height: 21px;
            text-align: left;
            font-style: normal;
            padding-bottom: 20px;
            width: 100%;
          }
          .user_group {
            width: 100%;
            background: #F6F8FA;
            border-radius: 10px;
            padding: 24px;
            margin-bottom: 20px;
            .avatar_group {
              margin-right: 20px;
              .avatar {
                @include background;
                width: 60px;
                height: 60px;
                background-size: cover;
                border-radius: 50%;
              }
            }
            .name_group {
              .name {
                font-weight: bold;
                font-size: 16px;
                color: #333333;
                line-height: 21px;
                text-align: left;
                font-style: normal;
                padding-bottom: 16px;
              }
              .com {
                font-size: 14px;
                color: #333333;
                line-height: 19px;
                text-align: left;
                font-style: normal;
              }
            }
          }
        }
        .content_group {
          width: 100%;
          height: 100%;
          overflow: hidden;
          .course_package_content_group {
            width: 100%;
            height: 100%;
            overflow: auto;
            padding: 20px;
            .content {
              width: 100%;
              height: 100%;
              overflow: auto;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.c_rl_course_package_check_select_list_group {
  .select_item {
    cursor: pointer;
    @include transition;
    width: 240px;
    height: 44px;
    border-radius: 10px;
    //border: 1px solid #ccc;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    &:hover {
      .icon {
        opacity: 1;
      }
      .text {
        margin-left: 0;
      }
      &.fail {
        background-color: #D82E0D;
        .icon {
          background-image: url(~@/assets/images/course_package/select_fail_on.png);
        }
        .text {
          color: #fff;
        }
      }
      &.audit {
        background-color: #F78900;
        .icon {
          background-image: url(~@/assets/images/course_package/select_audit_on.png);
        }
        .text {
          color: #fff;
        }
      }
      &.approve {
        background-color: #05AD00;
        .icon {
          background-image: url(~@/assets/images/course_package/select_approve_on.png);
        }
        .text {
          color: #fff;
        }
      }

    }
    &.fail {

      //color: #D82E0D;
      //.text {
      //  color: #D82E0D;
      //}
      .icon {
        background-image: url(~@/assets/images/course_package/select_fail.png);
      }
      .symbol {
        background-image: url(~@/assets/images/course_package/fail_open.png);
      }
    }
    &.audit {

      //color: #F78900;
      //.text {
      //  color: #F78900;
      //}
      .icon {
        background-image: url(~@/assets/images/course_package/select_audit.png);
      }
      .symbol {
        background-image: url(~@/assets/images/course_package/audit_open.png);
      }
    }
    &.approve {

      //color: #05AD00;
      //.text {
      //  color: #05AD00;
      //}
      .icon {
        background-image: url(~@/assets/images/course_package/select_approve.png);
      }
      .symbol {
        background-image: url(~@/assets/images/course_package/approve_open.png);
      }
    }
    .icon {
      @include transition;
      opacity: 0;
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .text {
      @include transition;
      margin-left: -26px;
      font-size: 16px;
      color: #333;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
    }
  }
}
</style>
