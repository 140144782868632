import { listConvertToModel, updateModel } from '@/models'
import CourseSection from '@/models/cource_package/course_section'
import Userinfo from '@/types/modules/Userinfo'
import request from '@/api/request'
import post from '@/utils/axios/post'
class CourcePackage {
  id
  chapters
  chatCount
  checkUserId
  checkUserName
  checkstatus
  collectCount
  content
  createTime
  createuser
  deleteflag
  docfile
  filenum
  getFlag
  likeCount
  ownedEnterprises
  reason
  signUserId
  studyCount
  tasknum
  taskteachers
  teachernum
  title
  type
  updateTime
  userName
  videofile
  webpic
  likeFlag
  customFlag




  constructor (params) {
    this.updateData(params)
  }

  async getData () {
    const res = await request.request(
      '/resource/queryCource',
      {
        courceId: this.id
      }
    )
    if (res.message === 'success') {
      this.updateData(res.contents.PCource)
      return true
    }
    return false
  }

  updateData (params) {
    const {
      chapters,
      createuser,
      taskteachers,
    } = params ?? {}

    updateModel(params, this)

    if (chapters) {
      this.chapters = listConvertToModel({
        list: chapters,
        model: CourseSection
      })
    } else {
      this.chapters = []
    }
    if (createuser) {
      this.createuser = new Userinfo(createuser)
    }
    if (taskteachers) {
      console.log(taskteachers)
      this.taskteachers = listConvertToModel({
        list: taskteachers,
        model: Userinfo
      })
    } else {
      this.taskteachers = []
    }
  }

  async create (params) {
    const res = await request.request(
      '/resource/createCource',
      params
    )
    if (res.message === 'success') {
      this.id = res.contents.PCource.id
      this.title = res.contents.PCource.title
      this.content = res.contents.PCource.content
      this.webpic = res.contents.PCource.webpic
      this.type = res.contents.PCource.type
      this.videofile = res.contents.PCource.videofile
      this.docfile = res.contents.PCource.docfile
      this.checkstatus = res.contents.PCource.checkstatus
      return true
    }
    return false
  }

  async updateInfo (params) {
    const res = await request.request(
    '/resource/updateCource',
    {
        courceId: this.id,
        title: params.title,
        content: params.content,
        webpic: params.webpic,
        type: params.type,
        videofile: params.videofile,
        docfile: params.docfile,
        checkstatus: this.checkstatus,
      }
    )
    if (res.message === 'success') {
      this.title = params.title
      this.content = params.content
      this.webpic = params.webpic
      this.type = params.type
      this.videofile = params.videofile
      this.docfile = params.docfile
      this.checkstatus = this.checkstatus
      return true
    }
    return false
  }

  async updateSection () {
    const res = await post.requestJson({
      url: '/resource/updateCourceChapter',
      data: {
        courceId: this.id,
        chapterlist: this.chapters
      }
    })
    return res.message === 'success'
  }

  async submit () {
    const res = await request.request(
      '/resource/updateCource',
      {
        courceId: this.id,
        title: this.title,
        content: this.content,
        webpic: this.webpic,
        type: this.type,
        videofile: this.videofile,
        docfile: this.docfile,
        checkstatus: 2,
      }
    )
    if (res.message === 'success') {
      this.checkstatus = 2
      return true
    }
    return false
  }

  async approve () {
    const res = await request.request(
      '/resource/checkCource',
      {
        type: 1,
        id: this.id,
        checkStatus: 3,
        reason: '',
      }
    )
    if (res.message === 'success') {
      this.checkstatus = 3
      return true
    }
    return false
  }

  async fail (params) {
    const {
      reason
    } = params
    const res = await request.request(
      '/resource/checkCource',
      {
        type: 1,
        id: this.id,
        checkStatus: 4,
        reason,
      }
    )
    if (res.message === 'success') {
      this.checkstatus = 4
      this.reason = reason
      return true
    }
    return false
  }

  async doDelete () {
    const res = await request.request(
      '/resource/deleteCource',
      {
        courceId: this.id
      }
    )
    return res.message === 'success'
  }

  async doLike () {
    const {
      likeFlag
    } = this
    const res = await request.request(
      '/resource/like',
      {
        id: this.id,
        type: 1,
        dotype: likeFlag ? 0 : 1,
      }
    )
    if (res.message === 'success') {
      if (likeFlag) {
        this.likeCount = this.likeCount ? this.likeCount - 1 : 0
      } else {
        this.likeCount = this.likeCount ? this.likeCount + 1 : 1
      }
      this.likeFlag = likeFlag ? 0 : 1
    }
    return res
  }


  /**
   * 领取
   * @return {Promise<void>}
   */
  async doGet () {
    const res = await request.request(
      '/resource/getCource',
      {
        id: this.id,
      }
    )
    if (res.message === 'success') {
      this.getFlag = 1
      // this.likeCount = this.likeCount ? this.likeCount + 1 : 1
    }
    return res
  }
}

export default CourcePackage
