var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "default_fun_aside" }, [
    _c("div", { staticClass: "top_group" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "fun_group" },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "沟通频道",
                placement: "right",
                effect: "light",
                "popper-class": "fun_aside_tooltip",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "fun fun_task",
                  class: { on: _vm.fun === "fun_task" },
                  attrs: { to: { name: "fun_task_index" } },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToRouter({ name: "fun_task_index" })
                    },
                  },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.new_task,
                        expression: "new_task",
                      },
                    ],
                    staticClass: "dot",
                  }),
                  _c("div", { staticClass: "icon" }),
                ]
              ),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "联系人频道",
                placement: "right",
                effect: "light",
                "popper-class": "fun_aside_tooltip",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "fun fun_contact",
                  class: { on: _vm.fun === "fun_contact" },
                  attrs: { to: { name: "fun_contact_index" } },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToRouter({ name: "fun_contact_index" })
                    },
                  },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.new_contact,
                        expression: "new_contact",
                      },
                    ],
                    staticClass: "dot",
                  }),
                  _c("div", { staticClass: "icon" }),
                ]
              ),
            ]
          ),
          _c(
            "el-tooltip",
            {
              attrs: {
                content: "我的模板",
                placement: "right",
                effect: "light",
                "popper-class": "fun_aside_tooltip",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "fun fun_template",
                  class: { on: _vm.fun == "fun_template" },
                  attrs: { to: { name: "fun_template_shopping_mail" } },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToRouter({ name: "fun_template_resource" })
                    },
                  },
                },
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.new_template,
                        expression: "new_template",
                      },
                    ],
                    staticClass: "dot",
                  }),
                  _c("div", { staticClass: "icon" }),
                ]
              ),
            ]
          ),
          _vm.arPermission
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "元基建",
                    placement: "right",
                    effect: "light",
                    "popper-class": "fun_aside_tooltip",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "fun fun_argumented_reality",
                      class: { on: _vm.fun === "fun_argumented_reality" },
                      attrs: { to: { name: "fun_argumented_reality" } },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.goToRouter({
                            name: "fun_argumented_reality_index",
                          })
                        },
                      },
                    },
                    [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.new_template && 0,
                            expression: "new_template && 0",
                          },
                        ],
                        staticClass: "dot",
                      }),
                      _c("div", { staticClass: "icon" }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "bottom_group" }, [
      _c(
        "div",
        {
          staticClass: "avatar_group",
          on: {
            click: function ($event) {
              return _vm.$tools.routeTo({ name: "personal_center_index" })
            },
          },
        },
        [
          _vm.user
            ? _c("img", {
                staticClass: "avatar cp",
                attrs: {
                  src: `${_vm.$avatar_url}${_vm.user.thumbnail}`,
                  alt: "",
                },
              })
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo_group" }, [
      _c(
        "a",
        {
          staticClass: "logo_a",
          attrs: { href: "https://pmcshome.yourutec.com/", target: "_blank" },
        },
        [
          _c("img", {
            staticClass: "logo",
            attrs: { src: require("@/assets/logo.png"), alt: "" },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }