var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "course_package d-flex flex-column bbox_wrapper" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "right",
            trigger: "hover",
            width: "100",
            "popper-class": "v_fum_template_resource_course_package_popover",
          },
          on: { close: _vm.handleClose },
          model: {
            value: _vm.switchMenu,
            callback: function ($$v) {
              _vm.switchMenu = $$v
            },
            expression: "switchMenu",
          },
        },
        [
          _c("div", { staticClass: "v_course_package_menu_list_group" }, [
            _vm.canDelete
              ? _c(
                  "div",
                  {
                    staticClass: "btn cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchDelete.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        删除\n      ")]
                )
              : _vm._e(),
            _vm.canRecover
              ? _c(
                  "div",
                  {
                    staticClass: "btn cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchRecover.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        恢复\n      ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "btn cp",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.touchShare.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        分享\n      ")]
            ),
          ]),
          _c("template", { slot: "reference" }, [
            _c(
              "div",
              {
                staticClass:
                  "menu_icon_group d-flex align-center justify-center",
              },
              [_c("div", { staticClass: "menu" })]
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "title_group",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchCoursePackage.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _c(
        "div",
        {
          staticClass: "pic_group",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.touchCoursePackage.apply(null, arguments)
            },
          },
        },
        [
          _vm.coursePackage.webpic
            ? [
                _c("div", {
                  staticClass: "pic",
                  style: {
                    backgroundImage: `url(${_vm.$file_url}${_vm.coursePackage.webpic})`,
                  },
                }),
                _c("div", {
                  staticClass: "pic img_pic",
                  style: {
                    backgroundImage: `url(${_vm.$img_url}${_vm.coursePackage.webpic})`,
                  },
                }),
              ]
            : _c(
                "div",
                {
                  staticClass:
                    "text_pic un_sel d-flex align-center justify-center",
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.coursePackage.title) + "\n    "
                  ),
                ]
              ),
        ],
        2
      ),
      _vm.showCheckAndTime
        ? _c(
            "div",
            {
              staticClass:
                "info_group d-flex align-center justify-space-between",
            },
            [
              _c("div", { staticClass: "time" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm
                        .$moment(
                          _vm.coursePackage.updateTime ||
                            _vm.coursePackage.createTime
                        )
                        .format("YYYY/MM/DD HH:mm")
                    ) +
                    "\n    "
                ),
              ]),
              _vm.coursePackage.checkstatus === 2 ||
              _vm.coursePackage.checkstatus === 4 ||
              1
                ? _c(
                    "div",
                    {
                      staticClass: "status_group d-felx align-center",
                      class: {
                        [_vm.statusType]: true,
                      },
                    },
                    [
                      _c("div", { staticClass: "icon" }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.statusText) + "\n      "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }