<template>
  <div
    class="views_shopping_mail_task_details"
  >
    <div class="summary_group bbox d-flex align-center justify-space-between"
      v-if="ptask"
    >
      <div class="info_group bbox">
        <div class="title_group d-flex align-center">
          <div class="title flex-shrink-0 mr-10">{{ptask.name}}</div>

          <div
            class="consult_group cp un_sel d-flex align-center mr-15"
            v-if="ptask.price !== 0"
            @click.stop="doConsulting();"
          >
            <img
              class="icon mr-3"
              src="~@/assets/images/shopping_mail/consult_icon.png"
            >
            <div class="consult"
            >购买咨询</div>
          </div>
          <div
            class="collect_group cp un_sel d-flex align-center mr-15"
            @click.stop="touchCollect()"
          >
            <img
              class="icon mr-3"
              src="~@/assets/images/shopping_mail/collect_icon.png"
              v-if="!ptask.collectionflag"
            >
            <img
              class="icon mr-3"
              src="~@/assets/images/shopping_mail/collect_icon_active.png"
              v-if="ptask.collectionflag"
            >
            <div class="collect"
            >{{ptask.collectionflag ? '已收藏' : '收藏'}}</div>
          </div>
        </div>
        <div
          class="sub_title flex-shrink-0 pt-10 d-flex align-start"

        >
          <div class="key flex-shrink-0 pr-5">简介：</div>
          <div class="content"
            v-html="ptask.introduction"
          ></div>
        </div>
        <div class="details pt-6"
          v-if="0"
        >PMCS-E102
          《会展策划与管理》配套实践任务模块
          《会展策划与管理》是学习会展经济与管理、会展策划与管理、会展服务与管理相关专业的必修课程，所有从事会展及相关工作的岗位均需要了解整个知识体系结构以及策划与管理原理。
          任务总数：65
          适用教材：
          《会展策划与管理》王春雷-高等教育出版社-2018年12月
          《会展业导论》王春雷（译）-重庆大学出版社-2018年11月
          《会展项目管理》刘大可-中国人民大学出版社-2017年4月
          《会展项目管理-理论、方法与实践》江金波-清华大学出版社-2014年2月
        </div>
        <div class="data_group pt-10 d-flex align-center">
          <div class="category mr-20"
            v-if="0"
          >会展专业 / 会展营销 / 市场营销</div>
          <img src="~@/assets/images/shopping_mail/time_icon.png" alt="" class="icon mr-4">
          <div class="text pr-20">{{$moment(ptask.createTime).format('YYYY年MM月DD日')}}</div>
          <template
            v-if="0"
          >
            <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon mr-4">
            <div class="text pr-20">9876</div>
            <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon mr-4">
            <div class="text">9.2w</div>
          </template>
        </div>
        <div class="lecturer_group d-flex align-center mt-10">
          <div class="text mr-10">讲师:</div>
          <img
            :src="`${$avatar_url}${ptask.createuserthumbnail}@!small200`"
            alt=""
            class="avatar mr-8"
          >
          <div class="name">{{ptask.createusername}}</div>
        </div>
      </div>
      <div
        v-if="showPay && canShowBuy"
        class="payment_group flex-shrink-0 ml-20"
      >

          <div
            class="button_group d-flex align-center mt-20"
          >
            <div
              v-if="!ptask.buy"
              class="button buy btn_dark pay"
              @click.stop="buyNow({ successText: '领取成功' })"
            >
              领取任务
            </div>
          </div>

      </div>
      <div
        v-if="payment !== false && !showPay"
        class="payment_group flex-shrink-0 ml-20"
      >
        <div class="sold_out pr-20"
          v-if="!ptask.showflag"
        >未上架</div>
        <template
          v-else
        >
          <div class="price"><span class="text">售价：</span><span class="number">¥{{ptask.price/100}}</span><span class="buy_hint"
            v-if="ptask.buy"
          >[已购买]</span></div>

          <div class="button_group d-flex align-center mt-20">
            <div class="button add mr-10"
              v-if="0"
            >加入定制清单</div>
            <div class="button buy"
              @click.stop="buyNow();"
            >{{ptask.price === 0 ? '免费领取' : '立即购买'}}</div>
          </div>

        </template>
      </div>
    </div>
    <div class="task_group mt-20">
      <div class="mask"></div>
      <task
        class="task_details_group"
        v-if="loading_init"
        :max_width="'none'"
        :max_height="'none'"
        :box_shadow="'box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06)'"
        :show_intro="false"
        :shopping_mail="true"
        :border="'border: 1px solid #E8EEFF'"
        :sell="true"
      ></task>
    </div>
    <div class="section"
      ref="task_list"
    >
      <title-module
        class="section_title_group mb-20"
        title="讲师其他课程"
        :show_more="false"
      ></title-module>
      <div class="list_group task_list_group d-flex align-start flex-wrap">
        <template
          v-for="(item, index) in lecturer_task"
        >
          <task-module
            @click.stop.native="viewTask(item)"
            v-if="index<task_line_number && item.id !== ptask.id"
            class="mr-16 task_module mb-16"
            :key="index"
            :ptask="item"
          ></task-module>
        </template>
      </div>
    </div>
    <div class="nomore">————  已经到底啦  ————</div>
  </div>
</template>

<script>
import Task from '@/components/Task/Container.vue';
import TaskModule from '@/views/ShoppingMail/Modules/Task/Unit/Major.vue';
import TitleModule from '@/views/ShoppingMail/Modules/Common/Title.vue';
import {PTask} from '@/types';
import { mapState } from 'vuex'
export default {
  props: {
    task_id: {
      type: Number,
      default: undefined,
    },
    payment: {
      type: Boolean,
      default: undefined,
    },
    showPay: {
      type: Boolean, default: false
    },
    freeToBuy: { type: Boolean, default: false }, // 是否可以免费领取
  },
  components: {
    Task,
    TitleModule,
    TaskModule,
  },
  computed: {
    ...mapState({
      coursePackage: state => state.course_package.data,
      coursePackageTask: state => state.course_package.task,
    }),
    canShowBuy () {
      const {
        coursePackage,
      } = this
      const {
        getFlag,
      } = coursePackage

      if (getFlag) {
        return false
      }
      return true
    }
  },
  data(){
    return {
      loading_init: false,
      ptask: undefined,
      lecturer_task: [],
      task_line_number: 5, // 任务单行数据
    }
  },
  watch: {
    '$route.params.task_id': function(val){
      if(val){
        // console.log(val);
        this.doClear();
        this.$nextTick(function(){
          this.init();
        })
      }
    }
  },
  mounted(){
    this.loading_init = false;
    this.init();
  },
  methods: {
    doClear(){
      this.$set(this, 'ptask', undefined);
      this.$set(this, 'lecturer_task', []);
    },
    async init(){
      // console.log(this, this.task_id)
      const {$route} = this;
      const {params} = $route;
      const {task_id} = params;
      await this.getTask({ task_id: task_id || this.task_id });
      this.loading_init = true;
      this.$nextTick(async function(){
        this.getTaskLineNumber();
        this.getLecturerShopTask();
      })
    },
    async getTask(params={}){
      const {task_id} = params;
      const taskId = task_id
      const res = await this.$api.shopping_mail.getTask({taskId})
      const {message, contents} = res;
      // // console.log(contents);
      if(message === 'success'){
        const {task} = contents;
        this.$set(this, 'ptask', new PTask(task))
        // // console.log(task);
      }
      return res;
    },
    async getLecturerShopTask(){
      const res = await this.ptask.getLecturerShopTask({start: this.lecturer_task.length});
      if(res){
        const {tasks} = res;
        const task_list = this.$types.transformObjectForList({
          list: tasks,
          obj: PTask,
        })
        this.$set(this, 'lecturer_task', task_list);
      }
    },
    getTaskLineNumber(){
      const unit_width                     = 200 + 16;
      const task_list                      = this.$refs.task_list;
      const {clientWidth: task_list_width} = task_list;
      const num = Math.floor(task_list_width/unit_width);
      this.task_line_number = num;
    },
    /**
     * 教学包预览任务
     */
    viewTask(item){
      this.$api.shopping_mail.pushNav({type: 'task', task_data: item, payment: true});
    },
    doConsulting(){
      this.$consulting({
        title: '咨询购买',
        subtitle: '您好，请确认并填写以下信息，方便工作人员与您及时联系。',
        enquireType: 1,
        paramId: this.task_id,
      })
      // this.$api.shopping_mail.doConsulting();
    },
    async buyNow(params){
      const {ptask, freeToBuy } = this;
      console.log(ptask, freeToBuy)
      const {userId} = this.$tools.getCookie('user');
      const {buy, price, signUserId} = ptask;
      if(userId === signUserId){
        this.$notice({desc: '无法购买自己的任务模板'});
        return ;
      }
      // 如果props传入freeToBuy 为真 则直接免费购买
      if(price === 0 || freeToBuy){
        if(buy){
          this.$notice({desc: '您已购买过此任务模板'});
          return;
        }
        const res = await ptask.freeToBuy();
        if(res){
          this.$notice({desc: params?.successText || '购买成功', type: 'success'});
        }else{
          this.$notice({desc: '失败'});
        }
        return;
      }
      let title = '填写订单信息';
      this.$buy_now({
        title,
        task: this.ptask,
      });
    },
    touchCollect(){

      const {ptask} = this
      const {collectionflag} = ptask
      if(collectionflag){
        // 取消收藏
        ptask.removeCollect()
        return
      }

      // 收藏
      const membership_privileges = this.$api.user.membershipPrivileges();
      if(!membership_privileges){
        return false
      }
      ptask.collect()
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_task_details{
  .summary_group{
    padding         : 30px;
    background-color: #fff;
    .info_group{
      flex: 1;
    }
    .avatar{
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 50%;
    }
    .picture_group{
      margin-right: 30px;
      width       : 30%;
      max-width   : 416px;
    }
    .picture{
      width: 100%;
      // max-width: 416px;
      // height: 234px;
      display      : block;
      border-radius: 4px;
    }
    .category{
      font-size  : 12px;
      color      : #AAAAAA;
      line-height: 16px;
    }
    .info_group{
      .data_group{
        font-size  : 12px;
        color      : #AAAAAA;
        line-height: 16px;
      }
    }
    .title{
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      line-height: 35px;
    }
    .consult_group{
      .consult{
        @include transition;
        font-size: 14px;
        color: #82929C;
      }
      // border-bottom: 1px solid transparent;
      &:hover{
        .consult{
          color: $main_blue;
        }
        // border-bottom-color: $main_blue;
      }
    }
    .collect_group{
      .collect{

        @include transition;
        font-size: 14px;
        color: #82929C;
      }
      &:hover{
        .collect{
          color: $main_blue;
        }
      }
    }
    .sub_title{
      font-size  : 14px;
      color      : #333333;
      line-height: 19px;
    }
    .details{
      @include scrollbar;
      overflow-y : auto;
      flex       : 1;
      width      : 100%;
      font-size  : 14px;
      color      : #666666;
      line-height: 26px;
      max-height : 26*2px;
      word-break : break-all;
    }
    .lecturer_group{
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    .payment_group{
      .price{
        color         : #F3580C;
        font-size     : 30px;
        font-weight   : bold;
        vertical-align: baseline;
        .number{
          font-size  : 36px;
          text-shadow: 0px 2px 4px rgba(243, 88, 12, 0.5);
        }
      }
      .buy_hint{
        font-size: 12px;
        color: #999;
        font-weight: normal;
      }
      .hint{
        font-size  : 18px;
        color      : #666666;
        line-height: 24px;
      }
      .payment{
        color        : #fff;
        padding      : 0 60px;
        line-height  : 48px;
        background   : #2BB06C;
        border-radius: 4px;
        text-align   : center;
      }
      .button{
        @include transition;
        font-size    : 16px;
        line-height  : 48px;
        border-radius: 4px;
        padding      : 0 40px;
        &:hover{
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
        }
        &.pay {
          background-color: $main_blue;
          color: #fff;
        }
      }
      .add{
        color           : #fff;
        background-image: linear-gradient(159deg, #68E5B6 0%, #016BA2 100%);
      }
      .buy{
        background-color: #D7E4F1;
        color           : $main_blue;
      }
    }
    .sold_out{
      font-size: 28px;
      color: #999;
      font-weight: bold;
    }
  }
  // 任务
  .task_group{
    position: relative;
    .task_details_group{
      position: relative;
      z-index : 2;
    }
    .mask{
      position: absolute;
      z-index : 3;
      width   : 100%;
      height  : 100%;
      left    : 0;
      top     : 0;
    }
  }
  .section{
  }
  .section_title_group{
    padding-top: 30px;
  }
  .nomore{
    width      : 100%;
    font-size  : 16px;
    color      : #D5D9DB;
    line-height: 21px;
    text-align : center;
    padding    : 50px 0;
  }
}
</style>
