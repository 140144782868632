var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_collect_file_index bbox d-flex flex-column" },
    [
      _c("div", { staticClass: "title_group flex-shrink-0 bbox" }, [
        _vm._v("\n    素材\n  "),
      ]),
      _c("div", { staticClass: "list_wrapper bbox" }, [
        _c(
          "div",
          {
            staticClass:
              "list_group bbox d-flex flex-wrap align-start justify-start",
          },
          [
            _vm._l(_vm.list, function (item, index) {
              return [
                _c("FileThumbnail", {
                  key: index,
                  staticClass: "file_thumbnail",
                  attrs: { "file-data": item },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.viewFile(item)
                    },
                  },
                }),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "loading_group un_sel d-flex justify-center" },
          [
            !_vm.noMore
              ? _c(
                  "div",
                  {
                    staticClass: "loading cp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.getMore()
                      },
                    },
                  },
                  [_vm._v("加载更多")]
                )
              : _c("div", { staticClass: "noMore" }, [_vm._v("没有更多了")]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }