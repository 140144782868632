<script>
import CoursePackageComponent from '@/views/fun_template/course_package'
export default {
  components: {
    CoursePackageComponent,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    noMore: {
      type: Boolean,
      detail: false,
    }
  }
}
</script>

<template>
  <div
    class="c_collect_file_index bbox d-flex flex-column "
  >
    <div
      class="title_group flex-shrink-0 bbox"
    >
      课程
    </div>
    <div class="list_wrapper bbox">
      <div class="list_group bbox d-flex flex-wrap align-start justify-start">
        <template
          v-for="(item,index) in list"
        >
          <CoursePackageComponent
            :key="index"
            :course-package="item"
            :show-check-and-time="false"
          ></CoursePackageComponent>
        </template>
      </div>
      <div
        class="loading_group un_sel d-flex justify-center"
      >
        <div
          v-if="!noMore"
          class="loading cp"
          @click.stop="getMore()"
        >
          加载更多
        </div>
        <div
          v-else
          class="nomore"
        >
          没有更多了
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.c_collect_file_index{
  width: 100%;
  overflow: auto;
  .title_group{
    width: 100%;
    font-size: 16px;
    color: $main_blue;
    line-height: 24px;
    border-left: 4px solid $main_blue;
    padding: {
      left: 20px;
    }
    margin: 10px 0;
    &.call_custom{
      margin-left: 20px;
    }
  }
  .list_wrapper{
    @include scrollbar;
    width: 100%;
    padding: 10px 20px;
    overflow: auto;
    flex: 1;
  }
  .list_group{
    width: 100%;
    flex: 1;
  }
  .loading_group{
    font-size: 14px;
    color: #999;
    padding: 10px 0;
    text-align: center;
    .loading{
      color: $main_blue;
    }
  }
}
</style>
