<template>
  <div
    class="components_dialog_operating_space_task_container_page bbox"
  >
    <div class="operation_group d-flex align-center bbox">
      <div
        class="create_group d-flex bbox justify-center ml-5 mr-5 un_sel cp"
        @click="createNew"
      >
        <div class="icon"></div>
        <div class="text pl-10">新建任务</div>
      </div>
      <search
        :list="tag_list"
        ref="search"
        :switch_task="switch_task"
        @handleChangeSwitch="changeSwitch"
        @handleChange="changeParams"
      ></search>
    </div>
    <list
      :switch_task="switch_task"
      ref="list"
      :list="task_list"
      :loading="loading"
      :nomore="nomore"
      @handleLoadmore="loadMoreTaskList"
    ></list>
  </div>
</template>

<script>
import Search from './Search.vue'
import List from './List.vue'
import store from '@/store'
export default {
  props: {
    switch_task: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Search,
    List,
  },
  data(){
    return {
      tag_list: [],
      // task_list: [],
      keyword: '',
      tag_id: '',
      loading: false,
      nomore: false,
    }
  },
  watch: {
    '$route.params.id': function(val){
      // console.log(val);
      this.nomore = false;
    }
  },
  computed: {
    task_list(){
      return this.$store.state.my_task_list;
    }
  },
  methods: {
    async getTagList(){
      const res = await this.$api.tag.getList();
      this.$set(this, 'tag_list', res.contents.tagList);
      this.$refs.search.init();
    },
    async getTaskList () {
      const { state } = store
      const { message_box_unit } = state
      const { loading, start, keyword, tag_id } = this
      if (loading) {
        return
      }
      this.loading = true
      const chat_type = this.$tools.getChatType()
      const chat_id = this.$api.moduleTask.getRouteParamsId()
      const is_all = 0
      const data = {
        is_all,
        start:this.task_list.length || 0,
        keyword,
        tag_id
      }
      if (message_box_unit) {
        data.chat_type = message_box_unit.chatType
        data.chat_id = message_box_unit.chatId
      } else {
        data.chat_type = chat_type
        data.chat_id = chat_id
      }
      // console.log(data)
      try {
        const res = await this.$api.taskList.getList(data)
        if (this.task_list.length == 0) {
          // this.$set(this, 'task_list', res.contents.taskList || [])
          this.$store.commit('set_my_task_list', res.contents.taskList || [])
        } else {
          // this.$set(this, 'task_list', this.task_list.concat(res.contents.taskList))
          this.$store.commit('set_my_task_list', this.task_list.concat(res.contents.taskList))
        }

        if (res.contents.taskList.length < 50) {
          this.nomore = true
        }
      } catch (e) {
        console.log('Error')
        console.log(e)
      }


      this.loading = false
    },
    loadMoreTaskList(){
      this.getTaskList();
    },
    clear(){
      this.$store.commit('set_my_task_list', []);
      // this.$set(this, 'task_list', []);
      this.$set(this, 'tag_list', []);
      this.keyword = '';
      this.tag_id = '';
      this.$refs.search.clear();
      this.$refs.list.clear();
    },
    init(){
      this.clear();
      this.$nextTick(function () {
        // console.log('获取任务区')
        this.getTagList();
        this.getTaskList();
      })
    },
    changeSwitch(payload){
      this.$emit('handleChangeSwitch', payload);
    },
    changeParams(params){
      const {loading} = this;
      if(loading){
        return;
      }
      const {keyword, tag_id} = params || {};
      if(keyword!==undefined){
        this.keyword = keyword;
      }
      if(tag_id !== undefined){
        this.tag_id = tag_id;
      }
      this.reloadTaskList();
    },
    reloadTaskList(){
      // this.$set(this, 'task_list', []);
      this.$store.commit('set_my_task_list', []);
      this.nomore = false;
      this.loading = false;
      this.getTaskList();
    },
    createNew(){
      this.$api.moduleTask.resetCallConfig();
      this.$nextTick(function(){
        this.$api.moduleTask.openTask({config:{create: true}});
      })
      // this.$api.moduleTask.openTask({config:{create: true}});
    }

  },
  mounted(){
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.components_dialog_operating_space_task_container_page{
  padding: 10px 15px;
  .create_group{
    @include transition;
    width        : 245px;
    height       : 36px;
    border       : 1px solid #F0F0F0;
    border-radius: 10px;
    &:hover{
      border: 1px solid #9ABEED;
    }
    .icon{
      background-image: url(~@/assets/images/dialog/fun/task/create.png);
      width: 20px;
      height: 20px;
    }
    .text{
      font-size: 14px;
      line-height: 20px;
      color: $main_blue;
    }
  }
}
</style>
