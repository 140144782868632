var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "v_resource_center_detail_type_microsoft_file d-flex justify-center",
      },
      [
        _c("div", { staticClass: "microsoft_file d-flex justify-center" }, [
          _c("div", { staticClass: "hint" }, [
            _vm._v("\n      请下载使用\n    "),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }