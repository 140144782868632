<script>
import FileThumbnail from '@/views/fun_template/resource/resource_center/file_thumbnail'
import store from '@/store'
export default {
  components: {
    FileThumbnail,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    noMore: {
      type: Boolean,
      detail: false,
    }
  },
  methods: {
    viewFile (data) {
      console.log(data)
      store.commit(
        'setResourceFileData',
        data
      )
    },
  }

}
</script>

<template>
  <div
    class="c_collect_file_index bbox d-flex flex-column "
  >
    <div
      class="title_group flex-shrink-0 bbox"
    >
      素材
    </div>
    <div class="list_wrapper bbox">
      <div class="list_group bbox d-flex flex-wrap align-start justify-start">
        <template
          v-for="(item, index) in list"
        >
          <FileThumbnail
            :key="index"
            class="file_thumbnail"
            :file-data="item"
            @click.native="viewFile(item)"
          ></FileThumbnail>
        </template>
      </div>
      <div class="loading_group un_sel d-flex justify-center">
        <div
          v-if="!noMore"
          class="loading cp"
          @click.stop="getMore()"
        >加载更多</div>
        <div
          v-else
          class="noMore"
        >没有更多了</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.c_collect_file_index{
  width: 100%;
  overflow: auto;
  .title_group{
    width: 100%;
    font-size: 16px;
    color: $main_blue;
    line-height: 24px;
    border-left: 4px solid $main_blue;
    padding: {
      left: 20px;
    }
    margin: 10px 0;
    &.call_custom{
      margin-left: 20px;
    }
  }
  .list_wrapper{
    @include scrollbar;
    width: 100%;
    padding: 10px 20px;
    overflow: auto;
    flex: 1;
  }
  .list_group{
    width: 100%;
    flex: 1;
    .file_thumbnail {
      margin: {
        right: 10px;
        bottom: 10px;
      }
    }
  }
  .loading_group{
    font-size: 14px;
    color: #999;
    padding: 10px 0;
    text-align: center;
    .loading{
      color: $main_blue;
    }
  }
}
</style>
