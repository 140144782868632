<script>
import { mapState } from 'vuex'
import store from '@/store'

export default {
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      // courseId: state => state.course_package.id,
      coursePackage: state => state.course_package.data,
      coursePackageList: state => state.course_package.packageList,
      coursePackageOperationData: state => state.course_package.operationData,
    }),
  },
  methods: {
    doClose () {
      this.$modal.hide('globalDeleteCoursePackage')
      store.commit(
        'setCoursePackageOperationData',
        undefined
      )
    },
    async doSure () {
      const {
        coursePackageOperationData,
        coursePackageList,
      } = this
      console.log(this.coursePackageOperationData, store)
      const res = await coursePackageOperationData.doDelete()
      console.log(res)
      if (res) {
        this.$notice({
          type: 'success',
          desc: '删除成功',
        })
        if (coursePackageList && coursePackageList.length) {
          const index = coursePackageList.findIndex((unit) => {
            return unit.id === coursePackageOperationData.id
          })
          if (index !== -1) {
            coursePackageList.splice(index, 1)
          }
        }
        this.doClose()
      }
    }
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="delete_confirm d-flex flex-column align-center justify-center"
>
  <div
    class="title"
  >
    确认删除?
  </div>
  <div
    class="sub_title"
  >
    温馨提示: 该操作无法撤销
  </div>
  <div
    class="fun_group d-flex align-center justify-center"
  >
    <div
      class="btn btn_light"
      @click.stop="doClose"
    >
      取消
    </div>
    <div
      class="btn btn_dark"
      @click.stop="doSure"
    >
      确认
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.delete_confirm {
  width: 100%;
  height: 100%;
  .title {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 15px;
  }
  .sub_title {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 26px;
  }
  .fun_group {
    .btn {
      margin: 0 10px;
      font-size: 14px;
      padding: 0 20px;
      line-height: 36px;
      border-radius: 4px;
    }
  }
}
</style>
