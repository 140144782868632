<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      linkError: false,
    }
  },
  computed: {
    ...mapState({
      // resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
    }),
    src () {
      if (!this.resourceFileData) {
        return ''
      }
      const {
        resourceFileData
      } = this
      const {
        linkUrl
      } = resourceFileData

      return linkUrl
    }
  },
  methods: {
    iframeError () {
      console.log('加载失败')
      this.linkError = true
    },
    goToUrl () {
      window.open(
        this.src,
        '_blank'
      )
    }
  },
  mounted () {
    console.log(this.$refs.iframe)
    setTimeout(() => {
      console.log(this.$refs.iframe.$el.readyState)
    },3000)
  }
}
</script>

<template>
  <div
    class="v_resource_center_detail_type_microsoft_file d-flex justify-center"
  >
    <div
      class="microsoft_file d-flex justify-center flex-column"
    >
      <div
        class="error_wrapper d-flex justify-center"
      >
        <div
          class="text"
        >如页面无法加载, 请</div>
        <div
          class="link cp px-2"
          @click="goToUrl"
        >
          点击此处
        </div>
        <div
          class="text"
        >
          在新页面中查看
        </div>
      </div>
      <iframe
        ref="iframe"
        :src="src"
        width="100%"
        height="600px"
        frameborder="0"
        onerror="iframeError"
      ></iframe>

    </div>
  </div>
</template>

<style scoped lang="scss">
.v_resource_center_detail_type_microsoft_file {
  width: 100%;
  .microsoft_file {
    width: 100%;
    max-width: 1000px;
  }
  .error_wrapper {
    width: 100%;
    padding: 0 0 20px;
    font-size: 13px;
    line-height: 26px;
    color: #333;
    .link {
      color: $main_blue;
    }
  }
}
</style>
