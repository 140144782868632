var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v_resource_center_detail_type_audio d-flex justify-center",
    },
    [
      _c("div", { staticClass: "microsoft_file d-flex justify-center" }, [
        _c("div", { staticClass: "img_wrapper d-flex justify-center" }, [
          _c("img", { attrs: { src: _vm.src, alt: "" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }